export type AuthAction = LoginSuccessAction | LogoutAction;

export type LoginSuccessAction = {
    type: 'ACCOUNT.LOGIN.SUCCESS';
    payload: { token: string };
};

export type LogoutAction = { type: 'ACCOUNT.LOGOUT' };

export const loginSuccess = (token: string): AuthAction => ({
    type: 'ACCOUNT.LOGIN.SUCCESS',
    payload: {
        token,
    },
});

export const logout = (): AuthAction => ({
    type: 'ACCOUNT.LOGOUT',
});
