import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, DataContext, PageHeading, TextInput } from '../components/atoms';
import { Layout } from '../components/organisms';
import { CarEquipment } from '../types/api';
import { deleteItem, guid, updateItem } from '../utils';

type EditableCarEquipment = CarEquipment & {
    isDeleted: boolean;
    isNew: boolean;
};

export const CarEquipmensPage = () => {
    const queryClient = useQueryClient();
    const [carEquipments, setCarEquipments] = useState<EditableCarEquipment[]>([]);
    const query = useQuery('carEquipments', () =>
        api()
            .get<CarEquipment[]>(Endpoints.CarEquipments)
            .then((res) =>
                setCarEquipments(
                    res.data
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((carEquipment) => ({
                            ...carEquipment,
                            isDeleted: false,
                            isNew: false,
                        })),
                ),
            ),
    );

    const mutation = useMutation(
        (carEquipments: CarEquipment[]) => {
            return api().post(Endpoints.CarEquipments, carEquipments);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['carEquipments']);
                toast.success('Výbava uložena');
            },
            onError: () => {
                toast.error('Výbavu se nepodařilo uložit');
            },
        },
    );

    const onAdd = () => {
        setCarEquipments([{ id: guid(), name: '', isDeleted: false, isNew: true }, ...carEquipments]);
    };

    const onNameChange = (name: string, carEquipment: EditableCarEquipment) => {
        setCarEquipments(
            updateItem(carEquipments, carEquipment, {
                ...carEquipment,
                name: name,
            }),
        );
    };

    const onDelete = (carEquipment: EditableCarEquipment) => {
        if (carEquipment.isNew) {
            setCarEquipments(deleteItem(carEquipments, carEquipment));
        } else {
            setCarEquipments(
                updateItem(carEquipments, carEquipment, {
                    ...carEquipment,
                    isDeleted: true,
                }),
            );
        }
    };

    const onUndo = (carEquipment: EditableCarEquipment) => {
        setCarEquipments(
            updateItem(carEquipments, carEquipment, {
                ...carEquipment,
                isDeleted: false,
            }),
        );
    };

    const onSave = () => {
        mutation.mutate(carEquipments.filter((carEquipment) => carEquipment.isDeleted === false));
    };

    return (
        <Layout>
            <PageHeading title="Výbava vozidel" icon="cogs" description="Seznam rozšířené výbavy vozidel">
                <div className="flex gap-3">
                    <Button variant="blue" size="md" text="Přidat" icon="plus" onClick={onAdd} />
                    <Button variant="blue" size="md" text="Uložit výbavu" icon="save" onClick={onSave} isLoading={mutation.isLoading} />
                </div>
            </PageHeading>
            <section>
                <DataContext isLoading={query.isLoading} isFailed={query.isError}>
                    <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
                        <thead className="bg-gray-50 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Název
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Akce
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {carEquipments?.map((carEquipment) => (
                                <tr className="">
                                    {carEquipment.isDeleted ? (
                                        <>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                <span className="border border-transparent px-3 py-2 text-red-500 line-through">
                                                    {carEquipment.name}
                                                </span>
                                            </td>
                                            <td className="w-[1%] whitespace-nowrap px-6 py-4 text-sm text-gray-700">
                                                <Button
                                                    variant="gray"
                                                    size="sm"
                                                    text="Obnovit"
                                                    icon="trash"
                                                    onClick={() => onUndo(carEquipment)}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                                <TextInput
                                                    name={`${carEquipment.id}-name`}
                                                    id={`${carEquipment.id}-name`}
                                                    value={carEquipment.name}
                                                    onChange={(value) => onNameChange(value, carEquipment)}
                                                    placeholder="--  Název výbavy  --"
                                                />
                                            </td>
                                            <td className="w-[1%] whitespace-nowrap px-6 py-4 text-sm text-gray-700">
                                                <div className="flex flex-col items-stretch">
                                                    <Button
                                                        variant="red"
                                                        size="sm"
                                                        text="Odstranit"
                                                        icon="trash"
                                                        onClick={() => onDelete(carEquipment)}
                                                    />
                                                </div>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </DataContext>
            </section>
        </Layout>
    );
};
