export const Endpoints = {
    Cars: {
        Index: '/api/cars',
        Sold: (page: number, size: number, producer: string) => `/api/cars/sold?pageNumber=${page}&pageSize=${size}&producer=${producer}`,
        Detail: (id: string) => `/api/cars/${id}`,
        CarData: (id: string) => `/api/cars/${id}/car-data`,
        CarEquipment: (id: string) => `/api/cars/${id}/car-equipment`,
        CebiaSmartCode: (id: string) => `/api/cars/${id}/cebia-smart-code`,
    },
    CarReservation: {
        Index: '/api/car-reservations',
        Create: '/api/car-reservations',
        Update: (id: string) => `/api/car-reservations/${id}`,
        Delete: (id: number) => `/api/car-reservations/${id}`,
        List: `/api/car-reservations`,
    },
    Notifications: {
        Index: '/api/notifications',
        Create: '/api/notifications',
        Update: (id: string) => `/api/notifications/${id}`,
        Delete: (id: string) => `/api/notifications/${id}`,
        List: `/api/notifications`,
    },
    Dashboard: '/api/dashboard',
    Account: {
        Login: '/api/account/login',
        ChangePassword: '/api/account/ChangePassword',
    },
    NewsletterSubscribers: '/api/NewsletterSubscribers',
    Forms: {
        Index: '/api/forms',
        Car: '/api/forms/car',
        Contact: 'api/forms/contact',
    },
    CarImport: '/api/car-import',
    WebSychronization: '/Api/WebSynchronization',
    CarEquipments: '/Api/car-equipments',
    Export: '/api/web-export',
};
