import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Router } from './Router';
import store from './redux';
import { classNames } from './utils';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: true,
        },
    },
});

setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
});

function App() {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Router />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    toastClassName={(context) => classNames(context?.defaultClassName, 'border shadow-xl')}
                />
            </QueryClientProvider>
        </Provider>
    );
}

export default App;
