import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { Button, Label, TextAreaInput, TextInput } from '../atoms';

type Props = {
    title: 'Vytvořit nové oznámení' | 'Upravit oznámení';
    isOpen: boolean;
    prefill?: ModalData;
    isUpdating: boolean;
    onCancel: () => void;
    onConfirm: (data: ModalData) => void;
};

type ModalData = {
    title: string;
    text: string;
};

export const NotificationModal = ({ title, isOpen, prefill, onCancel, isUpdating, onConfirm }: Props) => {
    const [reservationTitle, setReservationTitle] = useState('');
    const [reservationText, setReservationText] = useState('');

    useEffect(() => {
        setReservationTitle(prefill?.title ?? '');
        setReservationText(prefill?.text ?? '');
    }, [isOpen]);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => onCancel()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => onCancel()}
                                        >
                                            <span className="sr-only">Close</span>
                                            <FontAwesomeIcon icon="x" className="w-4" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="rounded-t-2xl border-b bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                    </div>
                                    <div className="grid grid-cols-2 gap-6 bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="col-span-2">
                                            <Label htmlFor="title">Titulek</Label>
                                            <TextInput
                                                name="title"
                                                id="title"
                                                value={reservationTitle}
                                                onChange={(value) => setReservationTitle(value)}
                                                placeholder="Změna otvírací doby"
                                                icon="heading"
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <Label htmlFor="message">Text</Label>
                                            <TextAreaInput
                                                name="message"
                                                id="message"
                                                value={reservationText}
                                                onChange={(value) => setReservationText(value)}
                                                rows={3}
                                            />
                                        </div>
                                    </div>

                                    <footer className="gap-3 rounded-b-2xl bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <Button
                                            variant="blue"
                                            size="md"
                                            text="Potvrdit"
                                            icon="check"
                                            isLoading={isUpdating}
                                            onClick={() => onConfirm({ title: reservationTitle, text: reservationText })}
                                        />
                                        <Button
                                            variant="light-gray"
                                            size="md"
                                            text="Zrušit"
                                            disabled={isUpdating}
                                            onClick={() => onCancel()}
                                        />
                                    </footer>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
