import { ReactNode } from 'react';
import { classNames } from '../../utils';

type Props = {
    children: ReactNode;
    padding?: boolean;
};

export const PageSection = ({ children, padding = true }: Props) => (
    <section className={classNames('', padding && 'p-4 md:p-6 2xl:p-8')}>{children}</section>
);
