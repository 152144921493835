export function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export function guid() {
    return crypto.randomUUID();
}

export function updateItem<T>(array: T[], oldItem: T, newItem: T) {
    const index = array.indexOf(oldItem);

    return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
}

export function deleteItem<T>(array: T[], oldItem: T) {
    const index = array.indexOf(oldItem);

    return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function parseJwt(token: string): { unique_name: string; role: string } {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}
