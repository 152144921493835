import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../redux/actions/account';
import { StoreState } from '../../types';
import { classNames } from '../../utils';
import { Logo } from '../atoms';

const navSections: NavSection[] = [
    {
        title: 'Hlavní',
        items: [
            {
                name: 'Přehled',
                href: '/',
                icon: 'home',
            },
            {
                name: 'Automobily',
                href: '/vozidla',
                icon: 'car',
            },
            {
                name: 'Výbava',
                href: '/vybava',
                icon: 'cogs',
            },
            {
                name: 'Rezervace',
                href: '/rezervace',
                icon: 'bookmark',
            },
            {
                name: 'Oznámení',
                href: '/oznameni',
                icon: 'bell',
            },
            {
                name: 'Synchronizace',
                href: '/synchronizace',
                icon: 'sync',
            },
        ],
    },
    {
        title: 'Formuláře',
        items: [
            {
                name: 'Poptávky',
                href: '/formulare/vozidla',
                icon: 'envelope',
            },
            {
                name: 'Kontakt',
                href: '/formulare/kontakt',
                icon: 'inbox',
            },
        ],
    },
    {
        title: 'Ostatní',
        items: [
            {
                name: 'Prodané automobily',
                href: '/prodane-automobily',
                icon: 'folder-closed',
            },
            {
                name: 'Nastavení',
                href: '/nastaveni',
                icon: 'cog',
            },
        ],
    },
];

type NavSection = {
    title: string;
    items: {
        name: string;
        href: string;
        icon: IconName;
        exact?: boolean;
    }[];
};

type Props = {
    children: ReactNode;
};

export const Layout = ({ children }: Props) => {
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const email = useSelector((state: StoreState) => state.auth.email);

    const onLogout = () => {
        dispatch(logout());
    };

    return (
        <div className="h-full">
            {/* Desktop */}
            <div className="hidden border-r-2 bg-gray-100 md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col xl:w-72">
                <div className="flex flex-shrink-0 items-center p-6">
                    <Logo />
                </div>
                <div className="flex flex-1 flex-col overflow-y-auto">
                    {navSections.map((section) => (
                        <section className="mt-5 px-4" key={section.title}>
                            <div className="mb-2 text-xs font-medium uppercase text-gray-500">{section.title}</div>
                            <nav className="space-y-2 text-sm">
                                {section.items.map((item) => (
                                    <NavLink
                                        to={item.href}
                                        key={item.name}
                                        end={item.href === '/' || item.href === '/formulare'}
                                        className={({ isActive }) =>
                                            classNames(
                                                'flex items-center gap-3 rounded-md px-3 py-3 tracking-wide transition duration-300 hover:bg-white',
                                                isActive ? 'bg-white font-semibold text-gray-950' : 'border-transparent text-gray-700',
                                            )
                                        }
                                    >
                                        {({ isActive }) => (
                                            <>
                                                <FontAwesomeIcon className={classNames(isActive ? 'text-blue-500' : '')} icon={item.icon} />
                                                {item.name}
                                            </>
                                        )}
                                    </NavLink>
                                ))}
                            </nav>
                        </section>
                    ))}
                </div>
                <div className="flex flex-shrink-0 items-center justify-between gap-4 px-8 py-6">
                    <button onClick={() => onLogout()} className="text-sm font-medium text-gray-800 transition hover:text-gray-950">
                        Odhlásit
                    </button>
                </div>
            </div>

            {/* Mobile */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute right-0 top-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Zavřít menu</span>
                                        <FontAwesomeIcon icon="times" className="text-white"></FontAwesomeIcon>
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="flex flex-shrink-0 items-center px-4 py-6">
                                    <Logo className="mx-auto h-8" />
                                </div>

                                {navSections.map((section) => (
                                    <section className="mt-5 px-4" key={section.title}>
                                        <div className="mb-2 text-xs font-medium uppercase text-gray-500">{section.title}</div>
                                        <nav className="space-y-1 text-sm">
                                            {section.items.map((item) => (
                                                <NavLink
                                                    to={item.href}
                                                    key={item.name}
                                                    className={({ isActive }) =>
                                                        classNames(
                                                            'flex items-center gap-3 rounded-md px-3 py-3 tracking-wide transition duration-300 hover:bg-gray-100',
                                                            isActive ? 'bg-gray-100 font-semibold text-gray-900' : 'text-gray-700',
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </nav>
                                    </section>
                                ))}
                            </div>
                            <div className="flex flex-shrink-0 items-center justify-between gap-4 p-4">
                                <button
                                    onClick={() => onLogout()}
                                    className="text-sm font-medium text-gray-800 transition hover:text-gray-950"
                                >
                                    Odhlásit
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
                </Dialog>
            </Transition.Root>

            <div className="flex min-h-full flex-1 flex-col md:pl-64 xl:pl-72">
                <div className="border-b bg-white md:hidden">
                    <button
                        type="button"
                        className="ml-auto items-center justify-center space-x-3 rounded-md px-4 py-4 text-gray-800 focus:outline-none"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <FontAwesomeIcon icon={'bars'}></FontAwesomeIcon>
                        <span>Otevřít menu</span>
                    </button>
                </div>
                <main className={classNames('min-h-full flex-1 bg-white')}>{children}</main>
            </div>
        </div>
    );
};
