import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { ContactForm } from '../types/api';
import { Card, CardSection, DataContext, LabeledValue, ButtonLink, PageHeading, PageSection } from '../components/atoms';
import { Layout } from '../components/organisms';

type UrlParams = {
    id: string;
};

export function ContactFormPage() {
    const id = useParams<UrlParams>().id;

    const {
        isLoading,
        isError,
        data: form,
    } = useQuery(['contactForms', id], () =>
        api()
            .get<ContactForm>(Endpoints.Forms.Contact + `/${id}`)
            .then((res) => res.data),
    );
    return (
        <Layout>
            <PageHeading title="Formulář - Kontakt" description="Informace o přijatém formuláři" icon="inbox" />
            <PageSection>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    {form && (
                        <div className="max-w-6xl">
                            <header className="space-y-1">
                                <h2 className="text-lg font-medium text-gray-900">Kontaktní formulář</h2>
                                <p className="text-sm text-gray-500">Formulář přijat {form.submitDate.toLocaleString('cs')}</p>
                            </header>
                            <div className="mt-5 border-t border-gray-200">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Jméno a příjmení</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.nameSurname}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.email}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.telephone}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Zpráva</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.message}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    )}
                </DataContext>
            </PageSection>
        </Layout>
    );
}
