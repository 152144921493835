import { ReactNode } from 'react';

type Props = {
    htmlFor: string;
    children: ReactNode;
};

export const Label = ({ htmlFor, children }: Props) => (
    <label htmlFor={htmlFor} className="mb-1 block text-sm font-medium text-gray-700">
        {children}
    </label>
);
