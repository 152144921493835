import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { classNames } from '../../utils';

type Props = {
    title: string;
    description?: string;
    icon?: IconName;
    image?: string;
    children?: React.ReactNode;
};

export const PageHeading = ({ title, description, icon, children, image }: Props) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    });

    const handleScroll = (event: Event) => {
        setIsScrolled(window.scrollY > 20);
    };

    return (
        <header
            className={classNames(
                'sticky top-0 z-10 flex items-center gap-4 border-b-2 bg-white px-4 py-4 transition sm:px-8',
                isScrolled ? 'shadow-sm' : '',
            )}
        >
            {icon && (
                <span className="flex items-center justify-center rounded-md bg-blue-600 bg-gradient-to-br p-4 text-gray-100">
                    <FontAwesomeIcon icon={icon} className="h-5 w-5"></FontAwesomeIcon>
                </span>
            )}
            {image && (
                <span className="flex items-center justify-center overflow-hidden rounded-md bg-gradient-to-br from-blue-500 to-blue-600 text-gray-100 shadow-sm">
                    <img alt="" className="h-[3.25rem]" src={image}></img>
                </span>
            )}
            <div className="flex flex-grow flex-col overflow-hidden">
                <h1 className="text-elipsis text-xl font-bold text-gray-900 sm:text-2xl">{title}</h1>
                <p className="text-elipsis text-sm text-gray-600">{description}</p>
            </div>
            {children && <div>{children}</div>}
        </header>
    );
};
