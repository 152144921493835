export type LoginRequest = {
    email: string;
    password: string;
};

export type LoginResponse = {
    token: string;
};

export type DashboardResponse = {
    analytics: DashboardAnalytics;
    carCount: number;
    carFormsCount: number;
    contactFormsCount: number;
    soldCarCount: number;
    reservationsCount: number;
    notificationsCount: number;
};

export type DashboardAnalytics = {
    pageViews: DashboardStat;
    visitors: DashboardStat;
    visits: DashboardStat;
    bounces: DashboardStat;
    totalTime: DashboardStat;
};

export type DashboardStat = {
    value: number;
    change: number;
};

export type CarResponse = {
    id: number;
    producer: string;
    model: string;
    modelSupplement: string;
    photo: string;
    isVatExpel: boolean;
    price: number;
    vinCode: string;
    insertedDate: Date;
    toOperationDate: Date;
    hasServiceBook: boolean;
    hasCebiaCertificate: boolean;
    isReserved: boolean;
};

export type SoldCarsResponse = {
    items: SoldCar[];
    pagination: Pagination;
};

export type SoldCar = {
    id: number;
    producer: string;
    model: string;
    modelSupplement: string;
    price: number;
    dateCreated: Date;
    dateSold: Date;
    toOperationDate: Date;
    vinCode: string;
};

export type Pagination = {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPages: number;
    hasNext: boolean;
    hasPrevious: boolean;
};

export type CarDetailResponse = {
    id: number;
    producer: string;
    model: string;
    modelSupplement: string;
    construction: string;
    fuel: string;
    color: string;
    kiloWatts: number;
    gear: string;
    engineVolumeLitres: string;
    engineVolumeCubicCm: string;
    toOperationDate: Date;
    kilometers: number;
    extras: string[];
    remarks: string[];
    photos: string[];
    vinCode: string;
    vatExpelText: string;
    isVatExpel: boolean;
    price: number;
    originalPrice: number;
    insertedDate: Date;
    editedDate: Date;
    serviceBook?: FileInfo;
    cebiaCertificate?: FileInfo;
    autotracerSmartCodeUrl?: string;
    carEquipments: CarDetailEquipment[];
    isReserved: boolean;
    isDeleted: boolean;
    forms: {
        id: string;
        nameSurname: string;
        email: string;
        telephone: string;
        dateCreated: Date;
    }[];
    reservation?: {
        nameSurname: string;
        email: string;
        telephone: string;
        dateFrom: Date;
        dateTo: Date;
        dateCreated: Date;
        remark: string;
    };
};

export type CarDetailEquipment = {
    id: string;
    name: string;
    checked: boolean;
};

export type FileInfo = {
    url: string;
    name: string;
    extension: string;
    size: number;
};

export type CarEquipment = {
    id: string;
    name: string;
};

export type Form = {
    id: string;
    nameSurname: string;
    email: string;
    telephone: string;
    submitDate: Date;
    formType: FormType;
    newsletter: boolean;
    gdpr: boolean;
};

export type CarForm = Form & {
    carId?: number;
    message?: string;
    carName: string;
    isDeleted: boolean;
};

export type ContactForm = Form & {
    message?: string;
};

export enum FormType {
    Car,
    Contact,
}

export type ChangePasswordRequest = {
    email: string;
    oldPassword: string;
    password: string;
    passwordRepeat: string;
};

export type Role = 'Admin';

export type WorkflowRun = {
    id: number;
    startedAt: Date;
    status: WorkflowRunStatus;
};

export enum WorkflowRunStatus {
    Success,
    Failure,
    InProgress,
    Unknown,
}

export type ReservationsAvailableCar = {
    id: number;
    name: string;
    vinCode: string;
    photo: string;
};

export type ReservationResponse = {
    id: number;
    nameSurname: string;
    email: string;
    telephone: string;
    dateFrom: string;
    dateTo: string;
    dateCreated: Date;
    remark: string;
    producer: string;
    model: string;
    modelSupplement: string;
    carPhoto: string;
};

export type CreateReservationRequest = {
    carId: number;
    nameSurname: string;
    email: string;
    telephone: string;
    dateFrom: Date;
    dateTo: Date;
    remark: string;
};

export type UpdateReservationRequest = {
    carId: number;
    nameSurname: string;
    email: string;
    telephone: string;
    dateFrom: Date;
    dateTo: Date;
    remark: string;
};

export type NotificationResponse = {
    id: string;
    title: string;
    text: string;
    dateCreated: string;
    dateUpdated: string;
};

export type CreateNotificationRequest = {
    title: string;
    text: string;
};

export type UpdateNotificationRequest = {
    id: string;
    title: string;
    text: string;
};
