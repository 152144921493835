import { ReactNode } from 'react';

type Props = {
    title: string;
    children: ReactNode;
};

export const CardSection = ({ title, children }: Props) => (
    <section className="pt-12" aria-label={title}>
        <header className="mb-6 border-b pb-3">
            <h3 className="text-lg font-medium">{title}</h3>
        </header>
        {children}
    </section>
);
