import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { DataContext, PageHeading, PageSection, StackedList } from '../components/atoms';
import { Layout } from '../components/organisms';
import { ContactForm } from '../types/api';

export const ContactFormsPage = () => {
    const {
        isLoading,
        isError,
        data: forms,
    } = useQuery('contactForms', () =>
        api()
            .get<ContactForm[]>(Endpoints.Forms.Contact)
            .then((res) => res.data),
    );

    const sortedForms = [...(forms ?? [])].sort((a, b) => +b.submitDate - +a.submitDate);

    return (
        <Layout>
            <PageHeading title="Formuláře - Kontakt" icon="inbox" description="Přehled o všech přijmutých formulářich z kontaktní stánky" />
            <PageSection padding={false}>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    <StackedList data={sortedForms}>
                        {(form) => (
                            <Link
                                to={`/formulare/kontakt/${form.id}`}
                                key={form.id}
                                className="group block space-y-1 px-6 py-3 transition hover:z-10 hover:bg-gray-50 hover:shadow"
                            >
                                <div className="flex justify-between">
                                    <div className="font-semibold text-blue-800">{form.nameSurname}</div>
                                    <div className="flex items-center gap-3 text-gray-800 transition group-hover:text-gray-900">
                                        <FontAwesomeIcon
                                            icon="calendar-alt"
                                            className="text-sm text-gray-500 transition group-hover:text-gray-600"
                                        />
                                        {form.submitDate.toLocaleDateString('cs')}
                                    </div>
                                </div>
                                <div className="flex justify-between gap-12 text-xs text-gray-600 transition group-hover:text-gray-700">
                                    <div className="flex items-center gap-6">
                                        <div className="flex items-center gap-2">
                                            <FontAwesomeIcon icon="envelope" />
                                            <span>{form.email}</span>
                                        </div>
                                        <div className="hidden items-center gap-2 md:flex">
                                            <FontAwesomeIcon icon="phone" />
                                            <span className="whitespace-nowrap">{form.telephone}</span>
                                        </div>
                                    </div>
                                    <div className="block max-w-xl truncate">{form.message}</div>
                                </div>
                            </Link>
                        )}
                    </StackedList>
                </DataContext>
            </PageSection>
        </Layout>
    );
};
