import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { CarForm } from '../types/api';
import { Card, CardSection, DataContext, LabeledValue, ButtonLink, PageHeading, PageSection, Alert } from '../components/atoms';
import { Layout } from '../components/organisms';

type UrlParams = {
    id: string;
};

export function CarFormPage() {
    const id = useParams<UrlParams>().id;

    const {
        isLoading,
        isError,
        data: form,
    } = useQuery(['carForms', id], () =>
        api()
            .get<CarForm>(Endpoints.Forms.Car + `/${id}`)
            .then((res) => res.data),
    );
    return (
        <Layout>
            <PageHeading title="Formulář - Vozidlo" description="Informace o přijatém formuláři" icon="car" />
            <PageSection>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    {form && (
                        <div className="max-w-6xl">
                            <header className="space-y-1">
                                <h2 className="text-lg font-medium text-gray-900">
                                    Poptávka vozidla -{' '}
                                    {form.carId !== undefined ? (form.carName ? form.carName : form.carId) : 'Vozidlo již není v nabídce'}
                                </h2>
                                <p className="text-sm text-gray-500">Formulář přijat {form.submitDate.toLocaleString('cs')}</p>
                            </header>
                            <div className="mt-5 border-t border-gray-200">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Jméno a příjmení</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.nameSurname}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.email}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.telephone}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Zpráva</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{form.message}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                                        <dt className="text-sm font-medium text-gray-500">Vozidlo</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <Link
                                                to={`/vozidla/${form.carId}`}
                                                className="flex items-center justify-between rounded-md border border-gray-300 bg-white py-3 pl-3 pr-4 text-sm shadow-sm transition duration-300 hover:shadow"
                                            >
                                                <div className="flex w-0 flex-1 items-center">
                                                    <FontAwesomeIcon className="h-5 w-5 flex-shrink-0 text-gray-400" icon="car" />
                                                    <span className="ml-2 w-0 flex-1 truncate">
                                                        {form.carId !== undefined
                                                            ? form.carName
                                                                ? form.carName
                                                                : form.carId
                                                            : 'Vozidlo již není v nabídce'}
                                                    </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <span className="font-medium text-blue-500">Zobrazit</span>
                                                </div>
                                            </Link>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    )}
                </DataContext>
            </PageSection>
        </Layout>
    );
}
