import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { Button, Label, TextAreaInput, TextInput } from '../atoms';

type Props = {
    title: 'Vytvořit novou rezervaci' | 'Upravit rezervaci';
    isOpen: boolean;
    prefill?: ModalData;
    isUpdating: boolean;
    onCancel: () => void;
    onConfirm: (data: ModalData) => void;
};

type ModalData = {
    nameSurname: string;
    email: string;
    tel: string;
    from: string;
    to: string;
    remark: string;
};

export const ReservationModal = ({ title, isOpen, prefill, onCancel, isUpdating, onConfirm }: Props) => {
    const [nameSurname, setNameSurname] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [remark, setRemark] = useState('');

    useEffect(() => {
        setNameSurname(prefill?.nameSurname ?? '');
        setEmail(prefill?.email ?? '');
        setTel(prefill?.tel ?? '');
        setFrom(prefillDate(prefill?.from));
        setTo(prefillDate(prefill?.to));
        setRemark(prefill?.remark ?? '');
    }, [isOpen]);

    const prefillDate = (value?: string) => {
        if (value === undefined || value === null) return '';

        const date = new Date(value);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        const formattedStringValue = `${year}-${month}-${day}`;

        return formattedStringValue;
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => onCancel()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-4xl transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => onCancel()}
                                        >
                                            <span className="sr-only">Close</span>
                                            <FontAwesomeIcon icon="x" className="w-4" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="rounded-t-2xl border-b bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                    </div>
                                    <div className="grid grid-cols-2 gap-6 bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="col-span-2">
                                            <Label htmlFor="nameSurname">Jméno a příjmení zákazníka</Label>
                                            <TextInput
                                                name="nameSurname"
                                                id="nameSurname"
                                                value={nameSurname}
                                                onChange={(value) => setNameSurname(value)}
                                                placeholder="Jan Novák"
                                                icon="user"
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="email">Email zákazníka</Label>
                                            <TextInput
                                                name="email"
                                                id="email"
                                                value={email}
                                                onChange={(value) => setEmail(value)}
                                                placeholder="jan.novak@email.cz"
                                                icon="envelope"
                                                type="email"
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="tel">Telefonní číslo zákazníka</Label>
                                            <TextInput
                                                name="tel"
                                                id="tel"
                                                value={tel}
                                                onChange={(value) => setTel(value)}
                                                placeholder="+420 123 456 789"
                                                icon="phone"
                                                type="tel"
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="from">Rezervace od</Label>
                                            <TextInput
                                                name="from"
                                                id="from"
                                                value={from}
                                                onChange={(value) => setFrom(value)}
                                                placeholder=""
                                                icon="calendar-day"
                                                type="date"
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="to">Rezervace do</Label>
                                            <TextInput
                                                name="to"
                                                id="to"
                                                value={to}
                                                onChange={(value) => setTo(value)}
                                                placeholder=""
                                                icon="calendar-day"
                                                type="date"
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <Label htmlFor="remark">Poznámka</Label>
                                            <TextAreaInput
                                                name="remark"
                                                id="remark"
                                                value={remark}
                                                onChange={(value) => setRemark(value)}
                                                rows={4}
                                            />
                                        </div>
                                    </div>

                                    <footer className="gap-3 rounded-b-2xl bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <Button
                                            variant="blue"
                                            size="md"
                                            text="Potvrdit"
                                            icon="check"
                                            isLoading={isUpdating}
                                            onClick={() => onConfirm({ nameSurname, email, tel, from, to, remark })}
                                        />
                                        <Button
                                            variant="light-gray"
                                            size="md"
                                            text="Zrušit"
                                            disabled={isUpdating}
                                            onClick={() => onCancel()}
                                        />
                                    </footer>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
