import { ReactNode } from 'react';
import { Alert } from '.';

type Props = {
    isLoading: boolean;
    isFailed: boolean;
    children?: ReactNode;
};

export const DataContext = ({ isLoading, isFailed, children }: Props) => {
    return (
        <>
            {isLoading && (
                <svg
                    className="mx-auto h-10 w-10 animate-spin text-blue-800"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )}
            {isFailed && (
                <Alert variant="error">
                    <div>
                        <span className="font-medium text-red-800">
                            Nepodařilo se načíst data. <br />
                        </span>
                        <small className="text-xs">Pokuste se znovu načíst stránku, nebo konktaktujte administrátora</small>
                    </div>
                </Alert>
            )}
            {!isFailed && !isLoading && children}
        </>
    );
};
