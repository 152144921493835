import { ReactNode } from 'react';
import { classNames } from '../../utils';

type Props = {
    title: string;
    description?: string;
    header?: ReactNode;
    body: ReactNode;
    footer?: ReactNode;
    bodyPadding?: boolean;
};

export const Card = ({ title, description, header, body, footer, bodyPadding = true }: Props) => (
    <div className="flex flex-col overflow-hidden rounded-md border bg-white shadow-sm">
        <header className="flex items-center border-b border-gray-200 px-4 py-5 sm:px-6">
            <div className="flex-grow">
                <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
                {description && <p className="text-sm text-gray-500">{description}</p>}
            </div>
            {header && <div>{header}</div>}
        </header>
        <div className={classNames('flex-grow space-y-4', bodyPadding && 'px-4 py-5 sm:p-6')}>{body}</div>
        {footer && (
            <footer className="flex flex-wrap justify-end gap-4 border-t border-gray-100 bg-gray-50 px-4 py-4 sm:px-6">{footer}</footer>
        )}
    </div>
);
