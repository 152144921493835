import { useMutation } from 'react-query';
import { Endpoints } from '../api/endpoints';
import { Alert, Button, Card, PageHeading, PageSection } from '../components/atoms';
import { Layout } from '../components/organisms';
import { ChangePasswordRequest } from '../types/api';
import { useState } from 'react';
import { api } from '../api';
import { useSelector } from 'react-redux';
import { StoreState } from '../types';
import { InputGroup } from '../components/Forms';

export const SettingsPage = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const mutation = useMutation((request: ChangePasswordRequest) => api().post(Endpoints.Account.ChangePassword, request));

    const email = useSelector((state: StoreState) => state.auth.email);

    function onSubmit() {
        mutation.mutate({ email, oldPassword, password, passwordRepeat });
    }

    return (
        <Layout>
            <PageHeading title="Nastavení" icon="cog" description="Nastavení aplikace" />
            <PageSection>
                <div className="grid gap-5 lg:grid-cols-2">
                    <Card
                        title="Změna hesla"
                        body={
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                                className="space-y-4"
                            >
                                <InputGroup
                                    label="Staré heslo"
                                    type="password"
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={(value) => setOldPassword(value)}
                                />
                                <InputGroup
                                    label="Nové heslo"
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(value) => setPassword(value)}
                                />
                                <InputGroup
                                    label="Zopakujte nové heslo"
                                    type="password"
                                    name="passwordRepeat"
                                    value={passwordRepeat}
                                    onChange={(value) => setPasswordRepeat(value)}
                                />
                                {mutation.isError && <Alert variant="error">Nepodařilo se změnit heslo.</Alert>}
                                {mutation.isSuccess && <Alert variant="success">Heslo úspěšně změněno.</Alert>}
                            </form>
                        }
                        footer={
                            <Button
                                onClick={() => onSubmit()}
                                icon="check"
                                variant="blue"
                                isLoading={mutation.isLoading}
                                size="md"
                                text="Uložit změny"
                            />
                        }
                    />
                </div>
            </PageSection>
        </Layout>
    );
};
