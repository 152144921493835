import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { classNames } from '../../utils';

type Props = {
    variant: 'info' | 'error' | 'success' | 'warning';
    children: ReactNode;
    onClose?: () => void;
};

export const Alert = ({ variant, children, onClose }: Props) => {
    return (
        <div
            className={classNames(
                'flex items-start gap-4 rounded-md p-4 text-sm font-medium',
                variant === 'info' && 'bg-blue-50 text-blue-500',
                variant === 'error' && 'bg-red-100 text-red-700',
                variant === 'success' && 'bg-green-100 text-green-700',
                variant === 'warning' && 'bg-orange-200 text-orange-800',
            )}
            role="alert"
        >
            <FontAwesomeIcon
                icon={
                    variant === 'info'
                        ? 'info-circle'
                        : variant === 'error'
                          ? 'exclamation-circle'
                          : variant === 'success'
                            ? 'check-circle'
                            : 'exclamation-circle'
                }
                className="-mr-2 mt-1"
            />
            {children}
            {onClose && (
                <button onClick={() => onClose()} className="ml-auto rounded-md px-1">
                    <FontAwesomeIcon icon="times" />
                </button>
            )}
        </div>
    );
};
