import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

type Props = WithBrandIcon;

type WithBrandIcon = {
    variant: 'With brand icon';
    icon: IconName;
    name: string;
    footer: string;
    stat: number;
    link: string;
};

export const Stat = (props: Props) => {
    if (props.variant === 'With brand icon')
        return (
            <div className="relative overflow-hidden rounded-md border bg-white px-4 pb-12 pt-5 shadow-sm sm:px-6 sm:pt-6">
                <dt>
                    <div className="absolute rounded-md bg-blue-500 p-3">
                        <FontAwesomeIcon
                            icon={props.icon}
                            className="flex h-6 w-6 items-center justify-center text-white"
                            aria-hidden="true"
                        />
                    </div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">{props.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-2">
                    <p className="text-2xl font-semibold text-gray-900">{props.stat}</p>
                    <div className="absolute inset-x-0 bottom-0 px-4 pb-4 sm:px-6">
                        <div className="text-sm">
                            <Link to={props.link} className="font-medium text-gray-600 transition hover:text-gray-900">
                                {props.footer}
                            </Link>
                        </div>
                    </div>
                </dd>
            </div>
        );

    return <></>;
};
