import { takeEvery } from 'redux-saga/effects';
import { LoginSuccessAction } from '../actions/account';

export function* loginSucessSaga(action: LoginSuccessAction) {
    const token = action.payload.token;

    localStorage.setItem('token', token);
    yield;
}

export function* logoutSaga() {
    localStorage.removeItem('token');
    yield;
}

export function* watchLoginSuccessSaga() {
    yield takeEvery('ACCOUNT.LOGIN.SUCCESS', loginSucessSaga);
}

export function* watchLogoutSaga() {
    yield takeEvery('ACCOUNT.LOGOUT', logoutSaga);
}

const accountSagas = [watchLoginSuccessSaga(), watchLogoutSaga()];

export default accountSagas;
