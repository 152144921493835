import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';
import { Spinner } from './Spinner';

type Props = {
    variant: 'red' | 'blue' | 'gray' | 'light-gray';
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    icon?: IconName;
    text: string;
    onClick?: () => void;
    isLoading?: boolean;
    type?: 'button' | 'submit' | 'reset';
    isFullWidth?: boolean;
    disabled?: boolean;
};

export const Button = ({ variant, size, icon, text, type, onClick, isLoading, isFullWidth, disabled }: Props) => (
    <button
        type={type ?? 'button'}
        onClick={() => onClick?.()}
        className={classNames(
            'inline-flex items-center justify-center gap-3 rounded-md border font-medium tracking-wide shadow-sm transition focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
            size === 'xs' && 'px-2.5 py-1.5 text-xs',
            size === 'sm' && 'px-3 py-2 text-sm',
            size === 'md' && 'px-4 py-2 text-sm',
            size === 'lg' && 'px-4 py-2 text-base',
            size === 'xl' && 'px-6 py-3 text-base',
            isFullWidth ? 'w-full' : '',
            icon ? 'pr-8' : '',
            variant === 'red' && 'border-transparent bg-red-600 text-white hover:bg-red-700',
            variant === 'blue' && 'border-transparent bg-blue-400 text-white hover:bg-blue-500',
            variant === 'gray' && 'border-transparent bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800',
            variant === 'light-gray' &&
                'bg-white text-gray-600 hover:border-gray-300 hover:text-gray-800 focus:ring-blue-400 disabled:bg-gray-50 disabled:text-gray-400 disabled:hover:border-gray-200',
        )}
        disabled={isLoading || disabled}
    >
        <span>{text}</span>
        {isLoading && (
            <Spinner
                className={classNames(
                    '-mr-4',
                    size === 'xs' && 'h-3 w-3',
                    size === 'sm' && 'h-3.5 w-3.5',
                    size === 'md' && 'h-4 w-4',
                    size === 'lg' && 'h-5 w-5',
                    size === 'xl' && 'h-6 w-6',
                )}
            ></Spinner>
        )}
        {!isLoading && icon && (
            <FontAwesomeIcon
                className={classNames(
                    '-mr-4',
                    size === 'xs' && 'h-3 w-3',
                    size === 'sm' && 'h-3.5 w-3.5',
                    size === 'md' && 'h-4 w-4',
                    size === 'lg' && 'h-5 w-5',
                    size === 'xl' && 'h-6 w-6',
                )}
                icon={icon}
            />
        )}
    </button>
);
