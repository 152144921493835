import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
    name: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    icon?: IconName;
    type?: 'text' | 'password' | 'email' | 'tel' | 'date' | 'datetime-local';
};

export const InputGroup = (props: Props) => {
    return (
        <div>
            <label htmlFor={props.name} className="mb-1 block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="flex rounded-md shadow-sm">
                {props.icon && (
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-600 sm:text-sm">
                        <FontAwesomeIcon className="h-3.5 w-3.5" icon={props.icon} />
                    </span>
                )}
                <input
                    type={props.type ?? 'text'}
                    name={props.name}
                    id={props.name}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    className={classNames(
                        'block w-full border border-gray-300 px-3 py-2 tracking-wide transition focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm',
                        props.icon ? 'rounded-r-md' : 'rounded-md',
                        (props.type === 'tel' || props.type === 'date') && '',
                        props.type === 'date' && props.value === '' ? 'text-gray-400' : '',
                    )}
                    placeholder={props.placeholder}
                />
            </div>
        </div>
    );
};
