import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { DataContext, Label, PageHeading, TextInput } from '../components/atoms';
import { Layout } from '../components/organisms';
import { CarResponse } from '../types/api';

export const CarsPage = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState('');

    const {
        isLoading,
        isError,
        data: cars,
    } = useQuery('cars', () =>
        api()
            .get<CarResponse[]>(Endpoints.Cars.Index)
            .then((res) => res.data),
    );

    const onRowClick = (id: number) => {
        navigate(`/vozidla/${id}`);
    };

    return (
        <Layout>
            <PageHeading title="Automobily" icon="car" description="Seznam všech automobilů v nabídce">
                <div className="flex items-end gap-4">
                    <div className="flex-col">
                        <Label htmlFor="filter">
                            <span className="text-xs">Hledat vozidlo</span>
                        </Label>
                        <TextInput
                            name="filter"
                            id="filter"
                            value={filter}
                            onChange={(value) => setFilter(value)}
                            icon="search"
                            placeholder=""
                        />
                    </div>
                </div>
            </PageHeading>
            <section>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    <table className="min-w-full divide-y divide-gray-200 border-b">
                        <thead className="bg-gray-50 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                            <tr>
                                <th scope="col" className="px-6 py-4">
                                    Vozidlo
                                </th>
                                <th scope="col" className="px-6 py-4">
                                    Cena
                                </th>
                                <th scope="col" className="px-6 py-4">
                                    VIN
                                </th>
                                <th scope="col" className="px-6 py-4">
                                    První registrace
                                </th>
                                <th scope="col" className="px-6 py-4">
                                    Vloženo
                                </th>
                                <th scope="col" className="px-6 py-4">
                                    Další informace
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {cars
                                ?.filter(
                                    (car) =>
                                        `${car.producer} ${car.model} ${car.modelSupplement} ${car.vinCode}`
                                            .toLocaleLowerCase()
                                            .indexOf(filter.toLocaleLowerCase()) !== -1,
                                )
                                .map((car) => (
                                    <tr
                                        key={car.id}
                                        className="group transition hover:cursor-pointer hover:bg-gray-50 hover:shadow"
                                        onClick={() => onRowClick(car.id)}
                                    >
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="flex items-center">
                                                <img className="h-20 rounded transition group-hover:shadow" src={car.photo} alt="" />
                                                <div className="ml-4 truncate">
                                                    <div className="mb-px text-lg font-semibold text-gray-900 transition group-hover:text-black">
                                                        {car.producer} {car.model}
                                                    </div>
                                                    <div className="text-sm text-gray-500 transition group-hover:text-gray-700">
                                                        {car.modelSupplement}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="mb-0.5 font-semibold text-gray-900">{car.price.toLocaleString('cs')} Kč</div>
                                            <div>
                                                {car.isVatExpel && (
                                                    <span className="-ml-2 inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800 transition group-hover:bg-gray-200">
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'check']}
                                                            className="mr-1.5 h-3 w-3 text-indigo-400"
                                                        />
                                                        Odpočet DPH
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-500 transition group-hover:text-gray-700">
                                            {car.vinCode}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                            {car.toOperationDate.toLocaleDateString('cs')}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-400 transition group-hover:text-gray-700">
                                            {car.insertedDate.toLocaleDateString('cs')}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="flex w-fit flex-col gap-x-3 gap-y-2">
                                                {car.hasServiceBook && (
                                                    <span className="flex items-center rounded bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-500 transition group-hover:shadow group-hover:shadow-blue-500/20">
                                                        <FontAwesomeIcon icon={['fas', 'cog']} className="mr-1.5 h-3 w-3 text-blue-400" />
                                                        Servisní kniha
                                                    </span>
                                                )}
                                                {car.hasCebiaCertificate && (
                                                    <span className="flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800 transition group-hover:shadow group-hover:shadow-green-500/20">
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'award']}
                                                            className="mr-1.5 h-3 w-3 text-green-600"
                                                        />
                                                        Certifikát cebia
                                                    </span>
                                                )}
                                                {car.isReserved && (
                                                    <span className="flex items-center rounded bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 transition group-hover:shadow group-hover:shadow-red-500/20">
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'registered']}
                                                            className="mr-1.5 h-3 w-3 text-red-600"
                                                        />
                                                        Rezervováno
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </DataContext>
            </section>
        </Layout>
    );
};
