import { classNames } from '../../utils';

type Props = {
    name: string;
    id: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    rows?: number;
};

export const TextAreaInput = ({ id, name, value, onChange, placeholder, rows = 3 }: Props) => {
    return (
        <div className="flex rounded-md shadow-sm">
            <textarea
                name={name}
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={classNames(
                    'block w-full rounded-md border border-gray-300 px-3 py-2 tracking-wide transition focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm',
                )}
                placeholder={placeholder}
                rows={rows}
            />
        </div>
    );
};
