import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
    name: string;
    id: string;
    value: string;
    onChange: (value: string) => void;
    icon?: IconName;
    options: string[];
};

export const SelectInput = ({ id, name, value, onChange, icon, options }: Props) => {
    return (
        <div className="flex rounded-md shadow-sm">
            {icon && (
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-600 sm:text-sm">
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            <div className="relative">
                <select
                    id={id}
                    name={name}
                    className={classNames(
                        'block w-full appearance-none border border-gray-300 bg-white py-2 pl-4 pr-10 text-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500',
                        icon ? 'rounded-r-md' : 'rounded-md',
                    )}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                >
                    <option value="">Vše</option>
                    {options.map((o) => (
                        <option key={o}>{o}</option>
                    ))}
                </select>
                <FontAwesomeIcon
                    icon="chevron-down"
                    className="absolute bottom-1/2 right-2 translate-y-1/2 transform text-xs text-gray-700"
                />
            </div>
        </div>
    );
};
