import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { classNames } from '../../utils';

type Props = {
    variant: 'red' | 'blue' | 'gray' | 'light-gray';
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    icon?: IconName;
    text: string;
    to: string;
};

export const ExternalLink = ({ variant, size, icon, text, to }: Props) => (
    <a
        href={to}
        rel="noopener noreferrer external"
        target="_blank"
        className={classNames(
            'inline-flex items-center rounded border font-semibold shadow-sm transition focus:outline-none focus:ring-2 focus:ring-offset-2',
            size === 'xs' && 'px-2.5 py-1.5 text-xs',
            size === 'sm' && 'px-3 py-2 text-sm',
            size === 'md' && 'px-4 py-2 text-sm',
            size === 'lg' && 'px-4 py-2 text-base',
            size === 'xl' && 'px-6 py-3 text-base',
            variant === 'red' && 'border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500',
            variant === 'blue' && 'border-transparent bg-blue-400 text-white hover:bg-blue-500 focus:ring-blue-500',
            variant === 'gray' && 'border-transparent bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 focus:ring-gray-500',
            variant === 'light-gray' && 'bg-white text-gray-600 hover:border-gray-300 hover:text-gray-800 focus:ring-blue-400',
        )}
    >
        {icon && (
            <FontAwesomeIcon
                className={classNames(
                    size === 'xs' && 'mr-2 h-3 w-3',
                    size === 'sm' && 'mr-2.5 h-3.5 w-3.5',
                    size === 'md' && 'mr-3 h-4 w-4',
                    size === 'lg' && 'mr-3 h-5 w-5',
                    size === 'xl' && 'mr-3.5 h-6 w-6',
                )}
                icon={icon}
            />
        )}
        {text}
    </a>
);
