import { ReactNode } from 'react';

type Props = {
    title: string;
    children: ReactNode;
};

export const LabeledValue = ({ title, children }: Props) => (
    <div className="space-y-2">
        <dt className="text-sm font-semibold text-gray-500">{title}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{children}</dd>
    </div>
);
