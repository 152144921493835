import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Modal } from '../components/Overlays';
import { Alert, Button, DataContext, PageHeading, PageSection } from '../components/atoms';
import { Layout, NotificationModal } from '../components/organisms';
import { CreateNotificationRequest, NotificationResponse, UpdateNotificationRequest } from '../types/api';

export const NotificationsPage = () => {
    const queryClient = useQueryClient();

    const [notificationToDelete, setNotificationsToDelte] = useState<NotificationResponse | undefined>();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [notificationToEdit, setNotificationToEdit] = useState<NotificationResponse | undefined>();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const query = useQuery(['notifications'], () =>
        api()
            .get<NotificationResponse[]>(Endpoints.Notifications.List)
            .then((res) => res.data),
    );

    const deleteMutation = useMutation((id: string) => api().delete(Endpoints.Notifications.Delete(id)), {
        onSuccess: () => {
            queryClient.invalidateQueries(['notifications']);
            toast.success('Oznamení bylo smazáno');
            setNotificationsToDelte(undefined);
            setIsDeleteModalOpen(false);
        },
        onError: () => {
            toast.error('Rezervaci se nepodařilo zrušit');
        },
    });

    const updateMutation = useMutation(
        (request: UpdateNotificationRequest) => api().put(Endpoints.Notifications.Update(request.id), request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications']);
                toast.success('Oznámení bylo upraveno');
                setIsEditModalOpen(false);
            },
            onError: (error: AxiosError) => {
                toast.error('Oznámení se nepodařilo upravit - ' + error.response?.data);
            },
        },
    );

    const createMutation = useMutation((request: CreateNotificationRequest) => api().post(Endpoints.Notifications.Create, request), {
        onSuccess: () => {
            queryClient.invalidateQueries(['notifications']);
            toast.success('Oznámení bylo vytvořeno');
            setIsEditModalOpen(false);
        },
        onError: (error: AxiosError) => {
            toast.error('Oznámení se nepodařilo vytvořit - ' + error.response?.data);
        },
    });

    const onConfirmModal = (title: string, text: string) => {
        if (notificationToEdit) {
            updateMutation.mutate({ title, text, id: notificationToEdit.id });
        } else {
            createMutation.mutate({ title, text });
        }
    };

    return (
        <Layout>
            <PageHeading title="Oznámení" icon="bell" description="Přehled o aktivních oznámeních">
                <Button
                    variant="blue"
                    size="md"
                    text="Přidat oznámení"
                    icon="plus"
                    onClick={() => {
                        setNotificationToEdit(undefined);
                        setIsEditModalOpen(true);
                    }}
                />
            </PageHeading>
            <PageSection>
                <DataContext isLoading={query.isLoading} isFailed={query.isError}>
                    <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                        {query.data?.length === 0 && (
                            <div className="lg:col-span-2">
                                <Alert variant="info">Nenalezeno žádné oznámení</Alert>
                            </div>
                        )}
                        {query.data?.map((reservation) => (
                            <li key={reservation.id} className="flex flex-col overflow-hidden rounded-md border bg-white shadow-sm">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{reservation.title}</h3>
                                    <p className="mt-2 text-sm leading-relaxed text-gray-700">{reservation.text}</p>
                                    <dl className="mt-4 text-right text-xs text-gray-800">
                                        <div className="flex justify-end gap-2">
                                            <dt>Vytvořeno:</dt>
                                            <dd className="">
                                                {new Date(reservation.dateCreated).toLocaleDateString('cs')}{' '}
                                                {new Date(reservation.dateCreated).toLocaleTimeString('cs')}
                                            </dd>
                                        </div>
                                        <div className="mt-1 flex justify-end gap-2">
                                            <dt>Upraveno:</dt>
                                            <dd className="">
                                                {new Date(reservation.dateUpdated).toLocaleDateString('cs')}{' '}
                                                {new Date(reservation.dateUpdated).toLocaleTimeString('cs')}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <footer className="flex justify-end gap-4 border-t border-gray-100 bg-gray-50 px-4 py-4 sm:px-6">
                                    <Button
                                        variant="red"
                                        icon="x"
                                        text="Zrušit oznámení"
                                        size="md"
                                        onClick={() => {
                                            setNotificationsToDelte(reservation);
                                            setIsDeleteModalOpen(true);
                                        }}
                                    />
                                    <Button
                                        variant="blue"
                                        icon="pencil"
                                        text="Upravit oznámení"
                                        size="md"
                                        onClick={() => {
                                            setNotificationToEdit(reservation);
                                            setIsEditModalOpen(true);
                                        }}
                                    />
                                </footer>
                            </li>
                        ))}
                    </ul>
                </DataContext>
            </PageSection>
            <NotificationModal
                isOpen={isEditModalOpen}
                onCancel={() => setIsEditModalOpen(false)}
                title={notificationToEdit ? 'Upravit oznámení' : 'Vytvořit nové oznámení'}
                isUpdating={updateMutation.isLoading || createMutation.isLoading}
                prefill={notificationToEdit ? notificationToEdit : undefined}
                onConfirm={(data) => onConfirmModal(data.title, data.text)}
            />
            <Modal
                variant="Simple alert"
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title="Zrušit oznámení"
                onConfirm={() => deleteMutation.mutate(notificationToDelete?.id ?? '')}
            >
                Opravdu si přejete odstranit oznámení <span className="font-medium">{notificationToDelete?.title}</span> ?
            </Modal>
        </Layout>
    );
};
