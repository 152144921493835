import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, DataContext, Label, PageHeading, PageSection, SelectInput } from '../components/atoms';
import { Layout } from '../components/organisms';
import { SoldCarsResponse } from '../types/api';

export const SoldCarsPage = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [producer, setProducer] = useState('');

    const { isLoading, isError, data } = useQuery(
        ['sold-cars', page, producer, pageSize],
        () =>
            api()
                .get<SoldCarsResponse>(Endpoints.Cars.Sold(page, pageSize, producer))
                .then((res) => res.data),
        {
            keepPreviousData: true,
        },
    );

    const onRowClick = (id: number) => {
        navigate(`/vozidla/${id}`);
    };

    useEffect(() => {
        setPage(1);
    }, [producer]);

    return (
        <Layout>
            <PageHeading title="Prodané automobily" icon="folder-closed" description="Seznam prodaných automobilů">
                <Label htmlFor="filter">
                    <span className="text-xs">Značka vozidla</span>
                </Label>
                <SelectInput
                    name="producer"
                    id="producer"
                    value={producer}
                    onChange={(value) => setProducer(value)}
                    options={['Audi', 'Volkswagen', 'Seat', 'Škoda', 'BMW']}
                    icon="search"
                />
            </PageHeading>
            <PageSection padding={false}>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    <div className="flex flex-col">
                        <div className="overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200 border-b">
                                <thead className="bg-gray-50 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Vozidlo
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Cena
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            VIN
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            První registrace
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Vloženo
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Prodáno
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {data?.items.map((car) => (
                                        <tr
                                            key={car.id}
                                            className="group transition hover:cursor-pointer hover:bg-gray-50 hover:shadow"
                                            onClick={() => onRowClick(car.id)}
                                        >
                                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600 transition group-hover:text-gray-800">
                                                {car.producer} {car.model} {car.modelSupplement}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                                {car.price.toLocaleString('cs')} Kč
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                                {car.vinCode}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                                {new Date(car.toOperationDate).toLocaleDateString('cs')}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                                {new Date(car.dateCreated).toLocaleDateString('cs')}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 transition group-hover:text-gray-700">
                                                {new Date(car.dateSold).toLocaleDateString('cs')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={6}>
                                            <nav
                                                className="flex items-center justify-between bg-gray-50 px-4 py-3 sm:px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Zobrazeno <span className="font-medium">{1 + (page - 1) * pageSize}</span> až{' '}
                                                        <span className="font-medium">
                                                            {Math.min(page * pageSize, data?.pagination.totalCount!)}
                                                        </span>{' '}
                                                        z celkem <span className="font-semibold">{data?.pagination.totalCount}</span>{' '}
                                                        automobilů
                                                    </p>
                                                </div>
                                                <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
                                                    <div className="flex items-center space-x-2">
                                                        <label
                                                            htmlFor="location"
                                                            className="block whitespace-nowrap text-sm font-medium text-gray-700"
                                                        >
                                                            Počet položek na stránku
                                                        </label>
                                                        <div className="relative">
                                                            <select
                                                                id="location"
                                                                name="location"
                                                                className="block w-full appearance-none rounded-md border border-gray-300 bg-white py-2 pl-4 pr-10 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                                                                value={pageSize}
                                                                onChange={(e) => setPageSize(+e.target.value)}
                                                            >
                                                                <option>10</option>
                                                                <option>20</option>
                                                                <option>30</option>
                                                            </select>
                                                            <FontAwesomeIcon
                                                                icon="chevron-down"
                                                                className="absolute bottom-1/2 right-2 translate-y-1/2 transform text-xs text-gray-700"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Button
                                                        text="Předchozí"
                                                        variant="light-gray"
                                                        size="md"
                                                        disabled={data?.pagination.hasPrevious === false}
                                                        onClick={() => {
                                                            setPage(page - 1);
                                                        }}
                                                    />
                                                    <Button
                                                        text="Další"
                                                        variant="light-gray"
                                                        size="md"
                                                        disabled={data?.pagination.hasNext === false}
                                                        onClick={() => {
                                                            setPage(page + 1);
                                                        }}
                                                    />
                                                </div>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </DataContext>
            </PageSection>
        </Layout>
    );
};
