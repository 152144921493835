import { Role } from '../../types/api';
import { parseJwt } from '../../utils';
import { AuthAction } from '../actions/account';

export type AuthState = {
    isLoggedIn: boolean;
    role?: Role;
    email: string;
    token: string;
};

export const initialState: AuthState = {
    isLoggedIn: false,
    role: undefined,
    email: '',
    token: '',
};

export default function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case 'ACCOUNT.LOGIN.SUCCESS':
            const parsedToken = parseJwt(action.payload.token);

            return {
                ...state,
                isLoggedIn: true,
                role: parsedToken.role as Role,
                email: parsedToken.unique_name,
                token: action.payload.token,
            };

        case 'ACCOUNT.LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                email: '',
                role: undefined,
            };

        default:
            return state;
    }
}
