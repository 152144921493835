import { BrowserRouter as ReactRouter, Routes, Route } from 'react-router-dom';
import {
    HomePage,
    CarsPage,
    CarEquipmensPage,
    SynchronizationPage,
    CarFormsPage,
    ContactFormsPage,
    SettingsPage,
    CarFormPage,
    ContactFormPage,
    LoginPage,
    ReservationsPage,
    SoldCarsPage,
    NotificationsPage,
} from './pages';
import { CarPage } from './pages/CarPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StoreState } from './types';
import { loginSuccess } from './redux/actions/account';

export const Router = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: StoreState) => state.auth.isLoggedIn);

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            dispatch(loginSuccess(token));
        }
    }, [dispatch, token]);

    return (
        <ReactRouter>
            {isLoggedIn ? (
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/vozidla" element={<CarsPage />} />
                    <Route path="/vozidla/:id" element={<CarPage />} />
                    <Route path="/vybava" element={<CarEquipmensPage />} />
                    <Route path="/rezervace" element={<ReservationsPage />} />
                    <Route path="/oznameni" element={<NotificationsPage />} />
                    <Route path="/synchronizace" element={<SynchronizationPage />} />
                    <Route path="/formulare/vozidla" element={<CarFormsPage />} />
                    <Route path="/formulare/vozidla/:id" element={<CarFormPage />} />
                    <Route path="/formulare/kontakt" element={<ContactFormsPage />} />
                    <Route path="/formulare/kontakt/:id" element={<ContactFormPage />} />
                    <Route path="/prodane-automobily" element={<SoldCarsPage />} />
                    <Route path="/nastaveni" element={<SettingsPage />} />
                </Routes>
            ) : (
                <LoginPage />
            )}
        </ReactRouter>
    );
};
