import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    name?: string;
    url?: string;
    onChange: (file?: File) => void;
    onDelete: () => void;
};

export const FileInput = ({ name, url, onChange, onDelete }: Props) => {
    return (
        <div className="group relative flex rounded border border-gray-300 bg-white shadow-sm transition focus-within:border-blue-800 focus-within:ring-1 focus-within:ring-blue-800">
            <div className="flex items-center rounded-l-md border-r border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-500">
                Vyberte soubor
            </div>
            <div className="relative flex-1 truncate">
                <input
                    className="w-full px-3 py-2 text-sm opacity-0 focus:outline-none"
                    type="file"
                    name="serviceBook"
                    id="serviceBook"
                    onChange={(e) => onChange(e.currentTarget.files![0])}
                    accept="image/*, application/pdf"
                />
                {url ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url}
                        className="absolute left-4 top-1/2 -translate-y-1/2 underline decoration-transparent decoration-2 underline-offset-2 transition hover:text-blue-800 hover:decoration-blue-800"
                    >
                        {name}
                    </a>
                ) : (
                    name && <span className="absolute left-4 top-[0.675rem] text-sm">{name}</span>
                )}
            </div>
            {name && (
                <button
                    className="rounded-r-md border-l border-gray-300 bg-red-600 px-3 py-2 text-sm font-semibold text-red-50 transition hover:bg-red-700 hover:text-red-50 focus:outline-none"
                    onClick={() => onDelete()}
                >
                    <FontAwesomeIcon icon="trash" className="mr-3" />
                    Odstranit
                </button>
            )}
        </div>
    );
};
