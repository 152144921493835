import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, Fragment } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import {
    Alert,
    Button,
    Card,
    DataContext,
    ExternalLink,
    FileInput,
    Label,
    LabeledValue,
    PageHeading,
    PageSection,
    TextInput,
} from '../components/atoms';
import { Layout, ReservationModal } from '../components/organisms';
import { CarDetailResponse, CarEquipment, CreateReservationRequest, UpdateReservationRequest } from '../types/api';
import { updateItem } from '../utils';
import { Dialog, Transition } from '@headlessui/react';
import { AxiosError } from 'axios';

type UrlParams = {
    id: string;
};

type FileState = {
    name?: string;
    url?: string;
    file?: File;
    isDelete?: boolean;
};

export const CarPage = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const id = useParams<UrlParams>().id;

    const [filter, setFilter] = useState('');
    const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
    const [isReservationDeleteModalOpen, setIsReservationDeleteModalOpen] = useState(false);

    const {
        isLoading,
        isError,
        data: car,
    } = useQuery(
        ['cars', id],
        () =>
            api()
                .get<CarDetailResponse>(Endpoints.Cars.Detail(id ?? ''))
                .then((res) => res.data),
        {
            onSuccess: (res) => {
                setCarEquipments(res.carEquipments);
                setIsReserved(res.isReserved);
                setCebiaCertificate({
                    name: res.cebiaCertificate ? `${res.cebiaCertificate.name}${res.cebiaCertificate.extension}` : '',
                    url: res.cebiaCertificate?.url,
                    file: undefined,
                });
                setServiceBook({
                    name: res.serviceBook ? `${res.serviceBook.name}${res.serviceBook.extension}` : '',
                    url: res.serviceBook?.url,
                    file: undefined,
                });
            },
        },
    );

    const cebiaCertificateState: FileState = {
        name: car?.cebiaCertificate ? `${car.cebiaCertificate.name}${car.cebiaCertificate.extension}` : '',
        url: car?.cebiaCertificate?.url,
        file: undefined,
    };
    const serviceBookState: FileState = {
        name: car?.serviceBook ? `${car.serviceBook.name}${car.serviceBook.extension}` : '',
        url: car?.serviceBook?.url,
        file: undefined,
    };

    const [cebiaCertificate, setCebiaCertificate] = useState<FileState>(cebiaCertificateState);
    const [serviceBook, setServiceBook] = useState<FileState>(serviceBookState);
    const [isReserved, setIsReserved] = useState<boolean>(car?.isReserved ?? false);
    const [carEquipments, setCarEquipments] = useState(car?.carEquipments ?? []);

    const carDataMutation = useMutation(
        ({
            id,
            serviceBook,
            serviceBookDelete,
            cebiaCertificate,
            cebiaCertificateDelete,
            isReserved,
        }: {
            id: string;
            serviceBook: File | undefined;
            serviceBookDelete: boolean | undefined;
            cebiaCertificate: File | undefined;
            cebiaCertificateDelete: boolean | undefined;
            isReserved: boolean;
        }) => {
            const formData = new FormData();
            isReserved && formData.append('isReserved', 'true');

            if (serviceBook) {
                formData.append('serviceBook', serviceBook);
                formData.append('serviceBookEdit', true.toString());
            }

            if (serviceBookDelete) {
                formData.append('serviceBookEdit', true.toString());
            }

            if (cebiaCertificate) {
                formData.append('cebiaCertificate', cebiaCertificate);
                formData.append('cebiaCertificateEdit', true.toString());
            }

            if (cebiaCertificateDelete) {
                formData.append('cebiaCertificateEdit', true.toString());
            }

            return api().put(Endpoints.Cars.CarData(id), formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['cars', id]);
                toast.success('Informace o vozidlu byla uložena');
            },
            onError: () => {
                toast.error('Informace o vozidlu se nepodařilo uložit');
            },
        },
    );

    function onCarDataSubmit() {
        carDataMutation.mutate({
            id: id ?? '',
            serviceBook: serviceBook.file,
            serviceBookDelete: serviceBook.isDelete,
            cebiaCertificate: cebiaCertificate.file,
            cebiaCertificateDelete: cebiaCertificate.isDelete,
            isReserved,
        });
    }

    const carEquipmentMutation = useMutation(
        ({ id, carEquipments }: { id: string; carEquipments: CarEquipment[] }) => api().put(Endpoints.Cars.CarEquipment(id), carEquipments),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['cars', id]);
                toast.success('Výbava vozidla byla uložena');
            },
            onError: () => {
                toast.error('Výbavu vozidla se nepodařilo uložit');
            },
        },
    );

    function onCarEquipmentSubmit() {
        carEquipmentMutation.mutate({
            id: id ?? '',
            carEquipments: carEquipments.filter((carEquipment) => carEquipment.checked),
        });
    }

    const onCarFormRowClick = (id: string) => {
        navigate(`/formulare/vozidla/${id}`);
    };

    const createReservationMutation = useMutation(
        (request: CreateReservationRequest) => api().post(Endpoints.CarReservation.Create, request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['cars', id]);
                toast.success('Rezervace byla vytvořena');
                setIsReservationModalOpen(false);
            },
            onError: (error: AxiosError) => {
                toast.error('Rezervaci se nepodařilo vytvořit - ' + error.response?.data);
            },
        },
    );

    const updateCarReservationMutation = useMutation(
        (request: UpdateReservationRequest) => api().put(Endpoints.CarReservation.Update(id ?? ''), request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['cars', id]);
                toast.success('Rezervace byla upravena');
                setIsReservationModalOpen(false);
            },
            onError: (error: AxiosError<string>) => {
                toast.error(error.response?.data ?? 'Rezervaci se nepodařilo upravit');
            },
        },
    );

    const updateCebiaSmartCodeMutation = useMutation(() => api().put(Endpoints.Cars.CebiaSmartCode(id ?? '')), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cars', id]);
            toast.success('Prověření bylo načteno');
        },
        onError: (error: AxiosError<string>) => {
            toast.error(error.response?.data ?? 'Prověření se nepodařilo načíst');
        },
    });

    const deleteMutation = useMutation((id: number) => api().delete(Endpoints.CarReservation.Index + `/${id}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(['cars', id]);
            toast.success('Rezervace byla zrušena');
            setIsReservationDeleteModalOpen(false);
        },
        onError: () => {
            toast.error('Rezervaci se nepodařilo zrušit');
        },
    });

    return (
        <Layout>
            <PageHeading
                title={car === undefined ? 'Načítávám vozidlo' : `${car.producer} ${car.model}`}
                description={car?.modelSupplement}
                image={car?.photos[0]}
            >
                {car && car.isDeleted && <Alert variant="error">Toto vozidlo již není v nabídce</Alert>}
            </PageHeading>
            <>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    <PageSection>
                        {car && (
                            <div className="grid grid-cols-1 gap-8 xl:grid-cols-2">
                                <Card
                                    title="Prověření vozidla Cebia Autotracer"
                                    header={
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-[28px]" viewBox="0 0 922 260">
                                                <defs>
                                                    <clipPath id="a">
                                                        <rect width="137.034" height="120.952" fill="#77e92b" />
                                                    </clipPath>
                                                </defs>
                                                <g transform="translate(-79 -100)">
                                                    <g transform="translate(796.963 171.024)">
                                                        <g clip-path="url(#a)">
                                                            <path
                                                                d="M135.55,56.9,80.138,1.483a5.063,5.063,0,0,0-7.161,0l-9.549,9.549a5.063,5.063,0,0,0,0,7.161L93.9,48.661H5.064A5.064,5.064,0,0,0,0,53.724v13.5a5.064,5.064,0,0,0,5.064,5.064H93.9L63.428,102.759a5.063,5.063,0,0,0,0,7.161l9.549,9.549a5.063,5.063,0,0,0,7.161,0L135.55,64.057a5.063,5.063,0,0,0,0-7.161"
                                                                transform="translate(0 0)"
                                                                fill="#77e92b"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g transform="translate(-536.871 -246)">
                                                        <path
                                                            d="M185.444,46.832a62.174,62.174,0,0,0-39.027,13.7V6.72L123,0V171.723l23.417-7.805v-5.9A62.444,62.444,0,1,0,185.444,46.832m0,101.473a39.028,39.028,0,1,1,39.027-39.027,39.027,39.027,0,0,1-39.027,39.027"
                                                            transform="translate(848.455 383.645)"
                                                            fill="#01215a"
                                                        />
                                                        <path
                                                            d="M213.508,139.438l-23.417,7.8V22.372l23.417,7.788Z"
                                                            transform="translate(921.864 408.123)"
                                                            fill="#01215a"
                                                        />
                                                        <path
                                                            d="M310.2,22.363v13.7a62.445,62.445,0,1,0,0,97.486v13.7l23.417-7.805V30.17ZM271.173,123.836A39.028,39.028,0,1,1,310.2,84.809a39.025,39.025,0,0,1-39.025,39.027"
                                                            transform="translate(942.255 408.114)"
                                                            fill="#01215a"
                                                        />
                                                        <path
                                                            d="M213.508,33.086l-23.417-7.8V1.864l23.417,7.8Z"
                                                            transform="translate(921.864 385.684)"
                                                            fill="#77e92b"
                                                        />
                                                        <path
                                                            d="M62.444,123.836A39.028,39.028,0,1,1,87.063,54.525l24.347-8.469a62.445,62.445,0,1,0,0,77.5l-24.347-8.469a38.871,38.871,0,0,1-24.619,8.745"
                                                            transform="translate(713.871 408.114)"
                                                            fill="#01215a"
                                                        />
                                                        <path
                                                            d="M62.229,124.053A39.245,39.245,0,1,1,87.12,54.468L111.609,46.3a62.444,62.444,0,1,0,0,77.007L87.12,115.149a39.076,39.076,0,0,1-24.891,8.9"
                                                            transform="translate(713.871 408.115)"
                                                            fill="#01215a"
                                                        />
                                                        <path
                                                            d="M179.7,96.517a62.454,62.454,0,1,0-12.18,26.8l-24.579-8.192a39.038,39.038,0,0,1-61.824-18.6ZM81.115,73.1a39.041,39.041,0,0,1,74.479,0Z"
                                                            transform="translate(775.045 408.115)"
                                                            fill="#01215a"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </>
                                    }
                                    body={
                                        <>
                                            {!car.autotracerSmartCodeUrl ? (
                                                <div className="flex h-full flex-col items-center justify-center text-center">
                                                    <FontAwesomeIcon icon="circle-question" className="mx-auto h-10 w-10 text-gray-400" />
                                                    <h3 className="mb-4 mt-4 text-sm font-medium tracking-wide text-gray-600">
                                                        Tento automobil zatím nebyl prověřen v systému Cebia AUTOTRACER. <br />
                                                        Prověřte automobil v systému Autotracer a následně klikněte na "načíst prověření".
                                                    </h3>
                                                    <p className="text-sm">
                                                        VIN Vozidla: <span className="select-all">{car.vinCode}</span>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="flex h-full flex-col items-center justify-center text-center">
                                                    <FontAwesomeIcon icon="circle-check" className="mx-auto h-10 w-10 text-green-500" />
                                                    <h3 className="mb-4 mt-4 text-sm font-medium tracking-wide text-gray-600">
                                                        Toto vozidlo bylo prověřeno v systému Cebia AUTOTRACER.
                                                    </h3>
                                                    <ExternalLink
                                                        size="sm"
                                                        variant="light-gray"
                                                        to={car.autotracerSmartCodeUrl}
                                                        icon="file"
                                                        text="Zobrazit prověření"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    }
                                    footer={
                                        <>
                                            <Button
                                                onClick={() => navigator.clipboard.writeText(car.vinCode)}
                                                size="sm"
                                                variant="light-gray"
                                                icon="copy"
                                                text="Zkopírovat VIN vozidla"
                                            />
                                            <ExternalLink
                                                size="sm"
                                                variant="light-gray"
                                                to="https://app.cebia.com/atrpro/"
                                                icon="external-link-alt"
                                                text="Přejít do systému Autotracer"
                                            />
                                            <Button
                                                onClick={() => updateCebiaSmartCodeMutation.mutate()}
                                                size="sm"
                                                variant="blue"
                                                icon="cloud-download"
                                                text="Načíst prověření"
                                            />
                                        </>
                                    }
                                />
                                <Card
                                    title="Servisní kniha a certifikát cebia"
                                    body={
                                        <form onSubmit={onCarDataSubmit} className="space-y-6">
                                            <div>
                                                <Label htmlFor="serviceBook">Servisní kniha</Label>
                                                <FileInput
                                                    name={serviceBook.name}
                                                    url={serviceBook.url}
                                                    onChange={(file) => setServiceBook({ name: file?.name, file: file })}
                                                    onDelete={() => setServiceBook({ isDelete: true })}
                                                />
                                            </div>

                                            <div>
                                                <Label htmlFor="cebiaCertificate">Certifikát Cebia</Label>
                                                <FileInput
                                                    name={cebiaCertificate.name}
                                                    url={cebiaCertificate.url}
                                                    onChange={(file) => setCebiaCertificate({ name: file?.name, file: file })}
                                                    onDelete={() => setCebiaCertificate({ isDelete: true })}
                                                />
                                                <div className="mt-2">
                                                    <Alert variant="info">
                                                        Certifikát cebia bude zobrazen pouze v případě, že vozidlo nemá prověření
                                                        autotracer.
                                                    </Alert>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                    footer={
                                        <>
                                            <Button
                                                icon="check"
                                                variant="blue"
                                                size="md"
                                                onClick={onCarDataSubmit}
                                                isLoading={carDataMutation.isLoading}
                                                text="Uložit změny"
                                            />
                                        </>
                                    }
                                />
                                <Card
                                    title="Doplňující výbava"
                                    bodyPadding={false}
                                    header={
                                        <div className="-my-2">
                                            <TextInput
                                                name="filter"
                                                id="filter"
                                                value={filter}
                                                onChange={(value) => setFilter(value)}
                                                icon="search"
                                                placeholder="Hledat dle názvu"
                                            />
                                        </div>
                                    }
                                    body={
                                        <form
                                            onSubmit={onCarDataSubmit}
                                            className="max-h-[20rem] divide-y divide-gray-200/75 overflow-auto border-b border-gray-100"
                                        >
                                            {carEquipments
                                                .filter(
                                                    (carEquipment) =>
                                                        carEquipment.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1,
                                                )
                                                .map((carEquipment) => (
                                                    <label
                                                        key={carEquipment.id}
                                                        htmlFor={`carEquipment-${carEquipment.id}`}
                                                        className="group relative flex cursor-pointer items-start px-4 py-3 transition hover:bg-gray-50/50"
                                                    >
                                                        <div className="min-w-0 flex-1 text-sm">
                                                            <span className="select-none font-medium text-gray-700 transition group-hover:text-gray-800">
                                                                {carEquipment.name}
                                                            </span>
                                                        </div>
                                                        <div className="ml-3 flex h-5 items-center">
                                                            <input
                                                                type="checkbox"
                                                                id={`carEquipment-${carEquipment.id}`}
                                                                name={`carEquipment-${carEquipment.id}`}
                                                                className="h-4 w-4 rounded border-gray-300 text-blue-500 accent-blue-400 focus:ring-blue-500"
                                                                checked={carEquipment.checked}
                                                                onChange={(e) => {
                                                                    setCarEquipments(
                                                                        updateItem(carEquipments, carEquipment, {
                                                                            ...carEquipment,
                                                                            checked: !carEquipment.checked,
                                                                        }),
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </label>
                                                ))}
                                        </form>
                                    }
                                    footer={
                                        <>
                                            <Button
                                                icon="check"
                                                variant="blue"
                                                size="md"
                                                onClick={onCarEquipmentSubmit}
                                                isLoading={carEquipmentMutation.isLoading}
                                                text="Uložit změny"
                                            />
                                        </>
                                    }
                                />
                            </div>
                        )}
                    </PageSection>
                    <PageSection>
                        <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
                            <Card
                                title="Rezervace automobilu"
                                bodyPadding={car?.reservation === null}
                                body={
                                    car?.reservation ? (
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Jméno a příjmení</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {car.reservation.nameSurname}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {car.reservation.email}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Telefonní číslo</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {car.reservation.telephone}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Datum rezervace</dt>
                                                <dd className="mt-1 flex space-x-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    <span>{new Date(car.reservation.dateFrom).toLocaleDateString('cs')}</span>
                                                    <span>-</span>
                                                    <span>{new Date(car.reservation.dateTo).toLocaleDateString('cs')}</span>
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Datum vytvoření</dt>
                                                <dd className="mt-1 flex space-x-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    <span>{new Date(car.reservation.dateCreated).toLocaleDateString('cs')}</span>
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                                <dt className="text-sm font-medium text-gray-500">Poznámka</dt>
                                                <dd className="mt-1 flex space-x-2 text-sm leading-relaxed text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {car.reservation.remark}
                                                </dd>
                                            </div>
                                        </dl>
                                    ) : (
                                        <div className="flex h-full flex-col items-center justify-center text-center">
                                            <FontAwesomeIcon icon="circle-info" className="mx-auto h-10 w-10 text-gray-400" />
                                            <h3 className="mb-4 mt-4 text-sm font-medium tracking-wide text-gray-600">
                                                Tento automobil zatím není rezervován
                                            </h3>
                                            <Button
                                                icon="plus"
                                                variant="blue"
                                                size="md"
                                                onClick={() => setIsReservationModalOpen(true)}
                                                text="Vytvořit rezervaci"
                                            />
                                        </div>
                                    )
                                }
                                footer={
                                    car?.reservation!! ? (
                                        <>
                                            <Button
                                                variant="red"
                                                icon="x"
                                                text="Zrušit rezervaci"
                                                size="md"
                                                onClick={() => {
                                                    setIsReservationDeleteModalOpen(true);
                                                }}
                                            />
                                            <Button
                                                variant="blue"
                                                icon="pencil"
                                                text="Upravit rezervaci"
                                                size="md"
                                                onClick={() => setIsReservationModalOpen(true)}
                                            />
                                        </>
                                    ) : undefined
                                }
                            />
                            <Card
                                title="Poptávky automobilu"
                                bodyPadding={car?.forms.length === 0}
                                body={
                                    <>
                                        {car?.forms.length === 0 ? (
                                            <div className="flex h-full flex-col items-center justify-center text-center">
                                                <FontAwesomeIcon icon="circle-info" className="mx-auto h-10 w-10 text-gray-400" />
                                                <h3 className="mt-3 text-sm font-medium tracking-wide text-gray-600">
                                                    Nenalezena žádná poptávka
                                                </h3>
                                            </div>
                                        ) : (
                                            <table className="min-w-full divide-y divide-gray-200 border-b">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Jméno
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Email
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Telefon
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                        >
                                                            Datum
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {car?.forms.map((form) => (
                                                        <tr
                                                            key={form.id}
                                                            className="group text-xs transition hover:cursor-pointer hover:bg-gray-50/50 hover:shadow-inner"
                                                            onClick={() => onCarFormRowClick(form.id)}
                                                        >
                                                            <td className="whitespace-nowrap px-6 py-4 text-gray-600 transition group-hover:text-gray-800">
                                                                {form.nameSurname}
                                                            </td>
                                                            <td className="whitespace-nowrap px-6 py-4 text-gray-500 transition group-hover:text-gray-700">
                                                                {form.email}
                                                            </td>
                                                            <td className="whitespace-nowrap px-6 py-4 text-gray-500 transition group-hover:text-gray-700">
                                                                {form.telephone}
                                                            </td>
                                                            <td className="whitespace-nowrap px-6 py-4 text-gray-500 transition group-hover:text-gray-700">
                                                                {new Date(form.dateCreated).toLocaleDateString('cs')}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </>
                                }
                            />
                        </div>
                    </PageSection>
                    <PageSection>
                        {car && (
                            <div className="md:px-4">
                                <h2 className="mb-8 text-xl font-medium text-gray-900">Informace ze systému teas</h2>
                                <dl className="grid gap-x-8 gap-y-10 sm:gap-x-12 md:grid-cols-2 xl:grid-cols-3">
                                    <LabeledValue title="ID vozidla">{car.id}</LabeledValue>
                                    <LabeledValue title="Značka">{car.producer}</LabeledValue>
                                    <LabeledValue title="Model">{car.model}</LabeledValue>
                                    <LabeledValue title="Informace k modelu">{car.modelSupplement}</LabeledValue>
                                    <LabeledValue title="Barva">{car.color}</LabeledValue>
                                    <LabeledValue title="Převodovka">{car.gear}</LabeledValue>
                                    <LabeledValue title="Palivo">{car.fuel}</LabeledValue>
                                    <LabeledValue title="Kilometry">{car.kilometers.toLocaleString('cs')} km</LabeledValue>
                                    <LabeledValue title="Výkon">{car.kiloWatts.toLocaleString('cs')} kW</LabeledValue>
                                    <LabeledValue title="Odpočet DPH">{car.isVatExpel ? 'ano' : 'ne'}</LabeledValue>
                                    <LabeledValue title="Cena">{car.price.toLocaleString('cs')} kč</LabeledValue>
                                    <LabeledValue title="Původní cena">{car.originalPrice.toLocaleString('cs')} kč</LabeledValue>
                                    <LabeledValue title="Informace k ceně">{car.vatExpelText}</LabeledValue>
                                    <LabeledValue title="VIN">{car.vinCode}</LabeledValue>
                                    <LabeledValue title="Datum do provozu">{car.toOperationDate.toLocaleDateString('cs')}</LabeledValue>
                                    <LabeledValue title="Datum vložení">{car.insertedDate.toLocaleDateString('cs')}</LabeledValue>
                                    <LabeledValue title="Datum poslední úpravy">{car.editedDate.toLocaleDateString('cs')}</LabeledValue>
                                </dl>
                                <section className="pt-12">
                                    <header className="mb-6 border-b pb-3">
                                        <h3 className="text-lg font-medium">Výbava</h3>
                                    </header>
                                    <ul className="grid list-none grid-cols-1 gap-x-4 gap-y-2 text-sm text-gray-800 md:grid-cols-2 xl:grid-cols-4">
                                        {car.extras.map((extra) => (
                                            <li key={extra}>
                                                <FontAwesomeIcon icon="angle-right" className="mr-2 text-xs text-blue-500" />
                                                {extra}
                                            </li>
                                        ))}
                                    </ul>
                                </section>
                                <section className="pt-12">
                                    <header className="mb-6 border-b pb-3">
                                        <h3 className="text-lg font-medium">Popis</h3>
                                    </header>
                                    <p className="leading-relaxed text-gray-700">
                                        {car.remarks.map((remark) => (
                                            <span key={remark}>
                                                {remark} <br />
                                            </span>
                                        ))}
                                    </p>
                                </section>
                                <section className="pt-12">
                                    <header className="mb-6 border-b pb-3">
                                        <h3 className="text-lg font-medium">Fotografie</h3>
                                    </header>
                                    <ul className="3xl:grid-cols-6 grid list-none grid-cols-2 gap-6 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                                        {car.photos.map((photo) => (
                                            <img
                                                src={photo}
                                                key={photo}
                                                alt={`${car.producer} ${car.model}`}
                                                loading="lazy"
                                                className="overflow-hidden rounded-md"
                                            />
                                        ))}
                                    </ul>
                                </section>
                            </div>
                        )}
                    </PageSection>

                    {car && (
                        <ReservationModal
                            title={car.reservation ? 'Upravit rezervaci' : 'Vytvořit novou rezervaci'}
                            isOpen={isReservationModalOpen}
                            isUpdating={car.reservation ? updateCarReservationMutation.isLoading : createReservationMutation.isLoading}
                            onCancel={() => setIsReservationModalOpen(false)}
                            prefill={
                                car.reservation
                                    ? {
                                          email: car.reservation.email,
                                          tel: car.reservation.telephone,
                                          nameSurname: car.reservation.nameSurname,
                                          from: car.reservation.dateFrom.toString(),
                                          to: car.reservation.dateTo.toString(),
                                          remark: car.reservation.remark,
                                      }
                                    : undefined
                            }
                            onConfirm={(data) =>
                                car.reservation
                                    ? updateCarReservationMutation.mutate({
                                          carId: car.id,
                                          nameSurname: data.nameSurname,
                                          email: data.email,
                                          telephone: data.tel,
                                          dateFrom: new Date(data.from),
                                          dateTo: new Date(data.to),
                                          remark: data.remark,
                                      })
                                    : createReservationMutation.mutate({
                                          carId: car.id,
                                          nameSurname: data.nameSurname,
                                          email: data.email,
                                          telephone: data.tel,
                                          dateFrom: new Date(data.from),
                                          dateTo: new Date(data.to),
                                          remark: data.remark,
                                      })
                            }
                        />
                    )}
                    {car && (
                        <Transition.Root show={isReservationDeleteModalOpen} as={Fragment}>
                            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setIsReservationDeleteModalOpen}>
                                <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                                            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                                    onClick={() => setIsReservationDeleteModalOpen(false)}
                                                >
                                                    <span className="sr-only">Close</span>
                                                    <FontAwesomeIcon icon="x" className="h-4 w-4" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <div className="p-6 sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <FontAwesomeIcon
                                                        icon="exclamation-triangle"
                                                        className="h-5 w-5 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                        Zrušit rezervaci
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm leading-relaxed text-gray-600">
                                                            Opravdu si přejete zrušit rezervaci pro vozidlo{' '}
                                                            <span className="font-medium">
                                                                {car.producer} {car.model} {car.modelSupplement}
                                                            </span>{' '}
                                                            ?
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <footer className="flex justify-end gap-3 bg-gray-50 px-6 py-4">
                                                <Button
                                                    variant="light-gray"
                                                    size="md"
                                                    text="Zrušit"
                                                    disabled={deleteMutation.isLoading}
                                                    onClick={() => setIsReservationDeleteModalOpen(false)}
                                                />
                                                <Button
                                                    variant="red"
                                                    icon="check"
                                                    size="md"
                                                    text="Potvrdit"
                                                    isLoading={deleteMutation.isLoading}
                                                    onClick={() => deleteMutation.mutate(car?.id ?? 0)}
                                                />
                                            </footer>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    )}
                </DataContext>
            </>
        </Layout>
    );
};
