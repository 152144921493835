import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Stat } from '../components/DataDisplay';
import { DataContext, PageHeading, PageSection } from '../components/atoms';
import { Layout } from '../components/organisms';
import { DashboardResponse } from '../types/api';
import { classNames } from '../utils';

export const HomePage = () => {
    const { isLoading, isError, data } = useQuery('home', () =>
        api()
            .get<DashboardResponse>(Endpoints.Dashboard)
            .then((res) => res.data),
    );

    const stats: { title: string; icon: IconName; value: number; change: number }[] = [
        {
            title: 'Zobrazení stránek',
            icon: 'file-alt',
            value: data?.analytics?.pageViews.value ?? 0,
            change: data?.analytics?.pageViews.change ?? 0,
        },
        {
            title: 'Návštěvy',
            icon: 'user-tag',
            value: data?.analytics?.visits.value ?? 0,
            change: data?.analytics?.visits.change ?? 0,
        },
        {
            title: 'Návštěvníci',
            icon: 'users',
            value: data?.analytics?.visitors.value ?? 0,
            change: data?.analytics?.visitors.change ?? 0,
        },
    ];

    const percentage = (value: number, change: number) => {
        const total = value - change;
        const percentage = (total === 0 ? 100 : (change / total) * 100).toFixed();
        return percentage;
    };

    return (
        <Layout>
            <PageHeading title="Přehled" icon="home" description="Souhrn dostupných informací" />
            <PageSection>
                <DataContext isLoading={isLoading} isFailed={isError}>
                    <div className="mb-5 overflow-hidden rounded-md border bg-white px-4 py-5 shadow-sm sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Návštěvnost za poslední 7 dní</h3>
                        <div className="grid grid-cols-3 gap-16 pt-6">
                            {stats.map((stat) => (
                                <div className="relative">
                                    <dt>
                                        <div className="absolute rounded-md bg-blue-500 p-3">
                                            <FontAwesomeIcon
                                                icon={stat.icon}
                                                className="flex h-6 w-6 items-center justify-center text-white"
                                            />
                                        </div>
                                        <p className="ml-16 truncate text-sm font-medium text-gray-500">{stat.title}</p>
                                    </dt>
                                    <dd className="ml-16 flex items-baseline gap-3">
                                        <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
                                        <div
                                            className={classNames(
                                                stat.change >= 0 ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800',
                                                'inline-flex items-center gap-1.5 rounded-md px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                                            )}
                                        >
                                            {stat.change >= 0 ? (
                                                <FontAwesomeIcon icon="arrow-up" className="text-xs" />
                                            ) : (
                                                <FontAwesomeIcon icon="arrow-down" className="text-xs" />
                                            )}
                                            {percentage(stat.value, stat.change)}%
                                        </div>
                                    </dd>
                                </div>
                            ))}
                        </div>
                        <div className="pt-6 text-sm">
                            <a
                                href="https://umami-selfhosted-lac.vercel.app/share/7f8fcRIC4rOuJL5h/autoaws.cz"
                                target="_blank"
                                rel="noopener noreferrer external"
                                className="font-medium text-gray-600 transition hover:text-gray-900"
                            >
                                Zobrazit všechny statistiky
                            </a>
                        </div>
                    </div>
                    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <Stat
                            variant="With brand icon"
                            icon="car"
                            name="Automobilů v nabíce"
                            link="/vozidla"
                            footer="Zobrazit všechny automobily"
                            stat={data?.carCount ?? 0}
                        />
                        <Stat
                            variant="With brand icon"
                            icon="envelope"
                            name="Poptávek automobilů"
                            link="/formulare/vozidla"
                            footer="Zobrazit všechny poptávky"
                            stat={data?.carFormsCount ?? 0}
                        />
                        <Stat
                            variant="With brand icon"
                            icon="bookmark"
                            name="Aktivních rezervací"
                            link="/rezervace"
                            footer="Zobrazit všechny rezervace"
                            stat={data?.reservationsCount ?? 0}
                        />
                        <Stat
                            variant="With brand icon"
                            icon="folder-closed"
                            name="Prodaných automobilů"
                            link="/prodane-automobily"
                            footer="Zobrazit všechny prodané automobily"
                            stat={data?.soldCarCount ?? 0}
                        />
                        <Stat
                            variant="With brand icon"
                            icon="inbox"
                            name="Kontaktních formulářů"
                            link="/formulare/kontakt"
                            footer="Zobrazit všechny kontaktní formuláře"
                            stat={data?.contactFormsCount ?? 0}
                        />
                        <Stat
                            variant="With brand icon"
                            icon="bell"
                            name="Aktivních oznámení"
                            link="/oznameni"
                            footer="Zobrazit všechny oznámení"
                            stat={data?.notificationsCount ?? 0}
                        />
                    </dl>
                </DataContext>
            </PageSection>
        </Layout>
    );
};
