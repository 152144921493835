import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
    name: string;
    id: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    icon?: IconName;
    type?: 'text' | 'password' | 'email' | 'tel' | 'date' | 'datetime-local';
};

export const TextInput = ({ id, name, value, onChange, placeholder, icon, type }: Props) => {
    return (
        <div className="flex rounded-md shadow-sm">
            {icon && (
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-600 sm:text-sm">
                    <FontAwesomeIcon className="h-3.5 w-3.5" icon={icon} />
                </span>
            )}
            <input
                type={type ?? 'text'}
                name={name}
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={classNames(
                    'block w-full border border-gray-300 px-4 py-2 tracking-wide shadow-sm transition focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm',
                    icon ? 'rounded-r' : 'rounded',
                    (type === 'tel' || type === 'date') && '',
                    type === 'date' && value === '' ? 'text-gray-400' : '',
                )}
                placeholder={placeholder}
            />
        </div>
    );
};
