import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Alert, Button, Label, Logo, TextInput } from '../components/atoms';
import { loginSuccess } from '../redux/actions/account';
import { LoginRequest, LoginResponse } from '../types/api';

export function LoginPage() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const mutation = useMutation((request: LoginRequest) => api().post<LoginResponse>(Endpoints.Account.Login, request), {
        onSuccess: (response) => {
            dispatch(loginSuccess(response.data.token));
        },
    });

    function onSubmit() {
        mutation.mutate({ email, password });
    }

    return (
        <main className="flex h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="mb-8">
                    <h1 className="mb-3.5 text-center text-4xl font-extrabold uppercase text-gray-800">
                        <Logo className="mx-auto h-10 w-auto text-blue-500" />
                    </h1>
                    <h2 className="text-center font-medium text-gray-500">Přihlašte se do svého účtu</h2>
                </div>
                <form className="space-y-5" action="#" method="POST">
                    <div>
                        <Label htmlFor="email">Emailová adresa</Label>
                        <TextInput
                            id="email"
                            name="email"
                            value={email}
                            onChange={(value) => setEmail(value)}
                            placeholder="email@domena.cz"
                            type="email"
                        />
                    </div>
                    <div>
                        <Label htmlFor="password">Heslo</Label>
                        <TextInput
                            id="password"
                            name="password"
                            value={password}
                            onChange={(value) => setPassword(value)}
                            placeholder="*******"
                            type="password"
                        />
                    </div>

                    <Button
                        variant="blue"
                        size="lg"
                        text="Přihlásit"
                        icon="sign-in"
                        onClick={onSubmit}
                        isLoading={mutation.isLoading}
                        isFullWidth
                    />
                    {mutation.isError && (
                        <Alert variant="error">
                            <>{(mutation.error as AxiosError)?.response?.data ?? 'Nepodařilo se přihlásit!'}</>
                        </Alert>
                    )}
                </form>
            </div>
        </main>
    );
}
