import { ReactNode } from 'react';
import { Alert } from '.';
import { classNames } from '../../utils';

type Props<T extends { id: string | number }> = {
    data: T[];
    children: (data: T) => ReactNode;
};

export function StackedList<T extends { id: string | number }>({ data, children }: Props<T>) {
    return (
        <ul className="list-none divide-y border-b bg-white">
            {data.map((datum) => {
                return (
                    <li key={datum.id} className={classNames('text-gray-800')}>
                        {children(datum)}
                    </li>
                );
            })}
            {data.length === 0 && <Alert variant="info">Nenalezen žádný záznam</Alert>}
        </ul>
    );
}
