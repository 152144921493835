import { Alert, Button, Card, DataContext, PageHeading, PageSection, Spinner } from '../components/atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Layout } from '../components/organisms';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { WorkflowRun, WorkflowRunStatus } from '../types/api';

export const SynchronizationPage = () => {
    const queryClient = useQueryClient();

    const cars = useQuery('synchronization-cars', () =>
        api()
            .get<Date>(Endpoints.CarImport)
            .then((res) => res.data),
    );
    const web = useQuery(
        'synchronization-web',
        () =>
            api()
                .get<WorkflowRun[]>(Endpoints.Export)
                .then((res) => res.data),
        {
            refetchOnWindowFocus: true,
        },
    );
    const carsMutation = useMutation(() => api().post(Endpoints.CarImport), {
        onSuccess: () => {
            queryClient.invalidateQueries('synchronization-cars');
        },
    });
    const webMutation = useMutation(() => api().post(Endpoints.Export), {
        onSuccess: () => {
            queryClient.invalidateQueries('synchronization-web');
            setTimeout(() => {
                queryClient.invalidateQueries('synchronization-web');
            }, 2000);
        },
    });
    return (
        <Layout>
            <PageHeading title="Synchronizace" icon="sync" description="Synchronizace dat aplikace" />
            <PageSection>
                <div className="grid gap-6 xl:grid-cols-2 2xl:gap-8">
                    <div>
                        <Card
                            title="Import - Vozidla"
                            body={
                                <>
                                    <p className="text-sm leading-relaxed text-gray-600">
                                        Načtení vozidel dat ze systému TEAS. <br />
                                        <strong className="font-semibold">
                                            Pro nahrání změn na web autoaws.cz je potřeba použít Export - Web
                                        </strong>
                                    </p>
                                    <DataContext isFailed={cars.isError} isLoading={cars.isLoading}>
                                        <Alert variant="info">
                                            Poslední úspěšná synchronizace proběhla{' '}
                                            <strong className="font-semibold">{cars.data?.toLocaleString('cs')}</strong>
                                        </Alert>
                                    </DataContext>
                                    {carsMutation.isError && (
                                        <Alert onClose={carsMutation.reset} variant="error">
                                            Nepodařilo se provést synchronizaci
                                        </Alert>
                                    )}
                                    {carsMutation.isSuccess && (
                                        <Alert onClose={carsMutation.reset} variant="success">
                                            Synchronizace provedena úspěšně
                                        </Alert>
                                    )}
                                </>
                            }
                            footer={
                                <Button
                                    onClick={carsMutation.mutate}
                                    variant="blue"
                                    icon="sync"
                                    size="md"
                                    isLoading={carsMutation.isLoading}
                                    text="Synchronizovat"
                                />
                            }
                        />
                    </div>
                    <Card
                        title="Export - Web"
                        body={
                            <>
                                <p className="mb-4 text-sm leading-relaxed text-gray-600">
                                    Nahrání vozidel na web autoaws.cz <br />
                                    Export na web může trvat několik minut v závisloti na objemu dat. <br />
                                </p>

                                <DataContext isFailed={web.isError} isLoading={web.isLoading}>
                                    {web.data &&
                                        web.data.map((run) => (
                                            <Alert
                                                variant={
                                                    run.status === WorkflowRunStatus.Unknown
                                                        ? 'warning'
                                                        : run.status === WorkflowRunStatus.InProgress
                                                          ? 'info'
                                                          : run.status === WorkflowRunStatus.Success
                                                            ? 'success'
                                                            : 'error'
                                                }
                                            >
                                                {run.status === WorkflowRunStatus.Unknown && 'Nepodařilo se zjistit stav'}
                                                {run.status === WorkflowRunStatus.InProgress && (
                                                    <>
                                                        {'Probíhá export'}
                                                        <Spinner className="h-4 w-4" />
                                                    </>
                                                )}
                                                {run.status === WorkflowRunStatus.Success && 'Export dokončen úspěšně'}
                                                {run.status === WorkflowRunStatus.Failure && 'Chyba při exportu'}
                                                {' : '}
                                                <strong className="font-semibold">{new Date(run.startedAt).toLocaleString('cs')}</strong>
                                            </Alert>
                                        ))}
                                </DataContext>
                                {webMutation.isError && (
                                    <Alert onClose={webMutation.reset} variant="error">
                                        Nepodařilo se spustis synchronizaci
                                    </Alert>
                                )}
                                {webMutation.isSuccess && (
                                    <Alert onClose={webMutation.reset} variant="info">
                                        Synchronizace spuštěna
                                    </Alert>
                                )}
                            </>
                        }
                        footer={
                            <div className="flex space-x-2">
                                <Button
                                    onClick={() => queryClient.invalidateQueries('synchronization-web')}
                                    variant="light-gray"
                                    size="md"
                                    text="Načíst aktuální stav  "
                                />
                                <Button
                                    onClick={webMutation.mutate}
                                    variant="blue"
                                    icon="sync"
                                    isLoading={webMutation.isLoading}
                                    size="md"
                                    text="Synchronizovat"
                                />
                            </div>
                        }
                    />
                </div>
            </PageSection>
        </Layout>
    );
};
