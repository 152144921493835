import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../atoms';

type Props = BaseProps & SimpleAlert;

type BaseProps = {
    isOpen: boolean;
    isUpdating?: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

type SimpleAlert = {
    variant: 'Simple alert';
    title: string;
    children: React.ReactNode;
};

export const Modal = (props: Props) => {
    if (props.variant === 'Simple alert')
        return (
            <Transition.Root show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={props.onClose}>
                    <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => props.onClose()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <FontAwesomeIcon icon="x" className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="p-6 sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <FontAwesomeIcon icon="exclamation-triangle" className="h-5 w-5 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Zrušit oznámení
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm leading-relaxed text-gray-600">{props.children}</p>
                                        </div>
                                    </div>
                                </div>
                                <footer className="flex justify-end gap-3 bg-gray-50 px-6 py-4">
                                    <Button
                                        variant="light-gray"
                                        size="md"
                                        text="Zrušit"
                                        disabled={props.isUpdating}
                                        onClick={() => props.onClose()}
                                    />
                                    <Button
                                        variant="red"
                                        icon="check"
                                        size="md"
                                        text="Potvrdit"
                                        isLoading={props.isUpdating}
                                        onClick={() => props.onConfirm()}
                                    />
                                </footer>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        );

    return <></>;
};
