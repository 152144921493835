import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Modal } from '../components/Overlays';
import { Button, DataContext, PageHeading, PageSection } from '../components/atoms';
import { Layout, ReservationModal } from '../components/organisms';
import { ReservationResponse, UpdateReservationRequest } from '../types/api';

export const ReservationsPage = () => {
    const queryClient = useQueryClient();

    const [reservationToDelete, setReservationToDelete] = useState<ReservationResponse | undefined>();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [reservationToEdit, setReservationToEdit] = useState<ReservationResponse | undefined>();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const query = useQuery(['reservations'], () =>
        api()
            .get<ReservationResponse[]>(Endpoints.CarReservation.List)
            .then((res) => res.data),
    );

    const deleteMutation = useMutation((id: number) => api().delete(Endpoints.CarReservation.Delete(id)), {
        onSuccess: () => {
            queryClient.invalidateQueries(['reservations']);
            toast.success('Rezervace byla zrušena');
            setReservationToDelete(undefined);
            setIsDeleteModalOpen(false);
        },
        onError: () => {
            toast.error('Rezervaci se nepodařilo zrušit');
        },
    });

    const updateCarReservationMutation = useMutation(
        (request: UpdateReservationRequest) => api().put(Endpoints.CarReservation.Update(request.carId.toString()), request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['reservations']);
                toast.success('Rezervace byla upravena');
                setIsEditModalOpen(false);
            },
            onError: (error: AxiosError) => {
                toast.error('Rezervaci se nepodařilo upravit - ' + error.response?.data);
            },
        },
    );

    return (
        <Layout>
            <PageHeading title="Rezervace automobilů" icon="bookmark" description="Přehled o rezervacích automobilů" />
            <PageSection>
                <DataContext isLoading={query.isLoading} isFailed={query.isError}>
                    <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2 2xl:grid-cols-3">
                        {query.data?.map((reservation) => (
                            <li key={reservation.email} className="flex flex-col overflow-hidden rounded-md border bg-white shadow-sm">
                                <div className="flex px-4 py-5 sm:px-6">
                                    <img src={reservation.carPhoto} className="-my-1 -ml-2 h-14 rounded" alt="" />
                                    <div className="pl-4">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                            {reservation.producer} {reservation.model}
                                        </h3>
                                        <p className="mt-0.5 max-w-2xl text-sm text-gray-500">{reservation.modelSupplement}</p>
                                    </div>
                                </div>
                                <dl className="border-gay-200 flex-1 border-t sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Jméno a příjmení</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{reservation.nameSurname}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{reservation.email}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Telefonní číslo</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{reservation.telephone}</dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Datum rezervace</dt>
                                        <dd className="mt-1 flex space-x-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <span>{new Date(reservation.dateFrom).toLocaleDateString('cs')}</span>
                                            <span>-</span>
                                            <span>{new Date(reservation.dateTo).toLocaleDateString('cs')}</span>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Datum vytvoření</dt>
                                        <dd className="mt-1 flex space-x-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <span>{new Date(reservation.dateCreated).toLocaleDateString('cs')}</span>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                                        <dt className="text-sm font-medium text-gray-500">Poznámka</dt>
                                        <dd className="mt-1 flex space-x-2 text-sm leading-relaxed text-gray-900 sm:col-span-2 sm:mt-0">
                                            {reservation.remark}
                                        </dd>
                                    </div>
                                </dl>
                                <footer className="flex justify-end gap-4 border-t border-gray-100 bg-gray-50 px-4 py-4 sm:px-6">
                                    <Button
                                        variant="red"
                                        icon="x"
                                        text="Zrušit rezervaci"
                                        size="md"
                                        onClick={() => {
                                            setReservationToDelete(reservation);
                                            setIsDeleteModalOpen(true);
                                        }}
                                    />
                                    <Button
                                        variant="blue"
                                        icon="pencil"
                                        text="Upravit rezervaci"
                                        size="md"
                                        onClick={() => {
                                            setReservationToEdit(reservation);
                                            setIsEditModalOpen(true);
                                        }}
                                    />
                                </footer>
                            </li>
                        ))}
                    </ul>
                </DataContext>
            </PageSection>
            <Modal
                variant="Simple alert"
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title="Zrušit rezervaci"
                onConfirm={() => deleteMutation.mutate(reservationToDelete?.id ?? 0)}
            >
                Opravdu si přejete zrušit rezervaci pro vozidlo{' '}
                <span className="font-medium">
                    {reservationToDelete?.producer} {reservationToDelete?.model} {reservationToDelete?.modelSupplement}
                </span>{' '}
                ?
            </Modal>
            <ReservationModal
                isOpen={isEditModalOpen}
                onCancel={() => setIsEditModalOpen(false)}
                title="Upravit rezervaci"
                isUpdating={updateCarReservationMutation.isLoading}
                prefill={
                    reservationToEdit
                        ? {
                              nameSurname: reservationToEdit.nameSurname,
                              email: reservationToEdit.email,
                              tel: reservationToEdit.telephone,
                              from: reservationToEdit.dateFrom,
                              to: reservationToEdit.dateTo,
                              remark: reservationToEdit.remark,
                          }
                        : undefined
                }
                onConfirm={(data) =>
                    updateCarReservationMutation.mutate({
                        carId: reservationToEdit?.id ?? 0,
                        nameSurname: data.nameSurname,
                        email: data.email,
                        telephone: data.tel,
                        dateFrom: new Date(data.from),
                        dateTo: new Date(data.to),
                        remark: data.remark,
                    })
                }
            />
        </Layout>
    );
};
